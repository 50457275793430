import { configureStore } from "@reduxjs/toolkit";
// import { createWrapper } from "next-redux-wrapper";
import { authSlice } from "./slices/auth";
import { menuSlice } from "./slices/menu";

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [menuSlice.name]: menuSlice.reducer,
  },
  devTools: true,
});

export default store;
