import axios from "axios";
import { AUTH } from "../config";
import Swal from "sweetalert2";
import moment from "moment";
import atob from "atob";

const downQualityImage = (e) => {
  const base64String = e;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64String;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const maxWidth = 800; // Adjust this as needed
      const maxHeight = 600; // Adjust this as needed
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.fillStyle = "#ffffff"; // White color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);

      const base64Image = canvas.toDataURL("image/jpeg", 0.7);

      resolve(base64Image);
    };
    img.onerror = (error) => reject(error);
  });
};

function base64ToBlob(base64, type = "application/octet-stream") {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type: type });
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const cloneData = (_data) => {
  return JSON.parse(JSON.stringify(_data));
};

const postData = async (_url, _obj) => {
  try {
    const res = await axios.post(_url, _obj, AUTH);

    return res;
  } catch (error) {
    if (error.response.status === 401) {
      Swal.fire({
        text: "Token expired กรุณาล็อกอินใหม่",
        showCloseButton: true,
        icon: "error",
        showConfirmButton: true,
        preConfirm: () => {
          localStorage.clear();
          window.location.href = "/login";
        },
      });
    } else {
      console.log(error.response);
    }

    return { data: { ok: false, message: [] } };
  }
};

const getData = async (_url, _obj) => {
  try {
    const res = await axios.get(_url, AUTH);

    return res;
  } catch (error) {
    if (error.response.status === 401) {
      return Swal.fire({
        text: "Token expired กรุณาล็อกอินใหม่",
        showCloseButton: true,
        icon: "error",
        showConfirmButton: true,
        preConfirm: () => {
          localStorage.clear();
          window.location.href = "/login";
        },
      });
    } else {
      console.log(error.response);
    }

    return { data: { ok: false, message: [] } };
  }
};

function genGroup(_arr, _cnt) {
  let arr = [];
  let rnd = 0;
  let ceil = Math.ceil(_arr.length / _cnt);

  _arr.forEach((x) => {
    if (arr[rnd]?.length === ceil) rnd++;
    if (!arr[rnd]) arr[rnd] = [];

    arr[rnd].push(x);
  });

  return arr;
}

function groupBy(_arr, f) {
  let groups = {};

  _arr.forEach(function (o) {
    let x = JSON.stringify(f(o));
    groups[x] = groups[x] || [];
    groups[x].push(o);
  });

  return groups;

  // return Object.keys(groups).map((x) => groups[x]);
}

const getRole = (_role) => {
  return JSON.parse(_role.replaceAll(" ", ""));
};

function findByKey(obj, targetKey) {
  if (obj === null || typeof obj !== "object") {
    return null;
  }

  if (targetKey in obj) {
    return obj[targetKey];
  }

  for (let key in obj) {
    const nestedResult = findByKey(obj[key], targetKey);
    if (nestedResult !== null) {
      return nestedResult;
    }
  }

  return null;
}

function getFutureDaysOfMonth(dayOfWeek) {
  const today = moment();
  const currentMonth = today.month();
  const futureDaysTimestamps = [];

  today.date(1);

  while (today.day() !== dayOfWeek) {
    today.add(1, "day");
  }

  while (today.month() === currentMonth) {
    if (today.isAfter(moment(), "day")) {
      futureDaysTimestamps.push(today.clone().valueOf());
    }

    today.add(7, "days");
  }

  return futureDaysTimestamps;
}

// const getYearsBy = (_start, _end) => {
//   let _year = new Date().getFullYear() - _end;
//   let _year02 = new Date().getFullYear() + _start;
//   let loopyear = [];
//   for (let index = _year; index <= _year02; index++) {
//     loopyear.push({
//       num_year: index + 543,
//       year: `${index} (${index + 543})`,
//     });
//   }
//   setYear(loopyear);
// };

const onPreview = async (file) => {
  let src = file.thumbUrl;

  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.thumbUrl);
      reader.onload = () => resolve(reader.result);
    });
  }

  if (!file.thumbUrl.includes("application/pdf")) {
    const image = new Image();
    image.src = src;

    const newWindow = window.open(src);
    newWindow?.document.write(image.outerHTML);
  } else {
    const blob = base64ToBlob(src.split(",")[1], "application/pdf");
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open(src);
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
    );
  }
};

const diffDays = (_s, _e) => {
  const date1 = moment(Number(_s));
  const date2 = moment(Number(_e));

  let weekend = 0;
  let currentDate = moment(date1);

  while (currentDate.isSameOrBefore(date2, "day")) {
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      weekend++;
    }

    currentDate.add(1, "day");
  }

  return weekend;
};

const betweenDays = (_sd, _ed, _st, _et) => {
  const date1 = moment(_sd);
  const date2 = moment(_ed);
  const time1 = moment(_st);
  const time2 = moment(_et);
  const hours1 = time1.hours();
  const minutes1 = time1.minutes();
  const hours2 = time2.hours();
  const minutes2 = time2.minutes();

  let between = [];
  let currentDate = moment(date1);

  while (currentDate.isSameOrBefore(date2, "day")) {
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      let cloneDate1 = currentDate.clone();
      let cloneDate2 = currentDate.clone();

      cloneDate1.set({
        hour: hours1,
        minute: minutes1,
      });
      cloneDate2.set({
        hour: hours2,
        minute: minutes2,
      });

      between.push([cloneDate1.valueOf(), cloneDate2.valueOf()]);
    }

    currentDate.add(1, "day");
  }

  return between;
};

function formatMoney(amount) {
  let amountStr = amount.toString();
  let parts = amountStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
}

export {
  toBase64,
  cloneData,
  getData,
  postData,
  genGroup,
  getRole,
  findByKey,
  groupBy,
  getFutureDaysOfMonth,
  base64ToBlob,
  onPreview,
  diffDays,
  betweenDays,
  downQualityImage,
  formatMoney,
};
