import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Form,
  Card,
  Menu,
  Table,
  Button,
  Input,
  Modal,
  Tag,
  Select,
  Switch,
} from "antd";
import { librarian, meeting, leasing, box } from "../image";
import { API_ENDPOINT } from "../config";
import LoadingComponent from "../components/loading";
import { getData, postData } from "../utils";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const items = [
  {
    label: "การจองห้อง",
    key: "room",
    icon: <img src={meeting} className="w-6" />,
    disabled: true,
  },
  {
    label: "การจองรถ",
    key: "car",
    icon: <img src={leasing} className="w-6" />,
    disabled: true,
  },
  {
    label: "การลา",
    key: "leave",
    icon: <img src={box} className="w-6" />,
    disabled: true,
  },
  {
    label: "การยืม",
    key: "borrow",
    icon: <img src={librarian} className="w-6" />,
    children: [
      {
        label: "Software & Hardware",
        key: "asset",
      },
    ],
  },
];

const SettingPage = () => {
  const userState = useSelector((state) => state.auth.authUser);

  const [formAsset] = Form.useForm();

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("asset");
  const [pathMenu, setPathMenu] = useState(["borrow", "asset"]);
  const [totalPage, setTotalPage] = useState(1);
  const [skipPage, setSkipPage] = useState({
    limit: 50,
    skip: 0,
  });
  const [modalContent, setModalContent] = useState({
    open: false,
    title: "",
  });

  const getQuery = async (_key, { skip, limit }) => {
    setLoading(true);

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: _key,
        // match: "deactive IS NULL OR deactive=false",
        sort: "create_at ASC",
        limit: limit,
        offset: limit * skip,
        projection: "*",
      },
      count: {
        match: "deactive IS NULL OR deactive=false",
        projection: "*",
        table: _key,
      },
    });

    if (res.data.ok) {
      let { data, total } = res.data.message;
      let num = limit * skip + 1;

      const result = data.map((x) => {
        x.no = num++;

        return x;
      });

      setDataSource(result);
      setTotalPage(total);
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  const getQueryBorrow = async () => {
    setLoading(true);

    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let dupMatch = `(CAST(time_start AS BIGINT) >= ${serverTime.data} OR CAST(time_end AS BIGINT) >= ${serverTime.data})\n`;

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "borrow",
        match: `(${dupMatch}) AND one_status = 'true'`,
        projection: "asset_id, COUNT(asset_id) AS total",
        group: `asset_id`,
      },
    });

    setLoading(false);

    if (res.data.ok) {
      return res.data.message;
    } else {
      console.log(res.data.message);
      return [];
    }
  };

  const onChangeSkip = async (page, row) => {
    let e = { skip: page - 1, limit: row };
    let tb = findCurrentMenu().key;

    setSkipPage(e);
    await getQuery(tb, e);
  };

  const handleDeactive = async (e, _status) => {
    setLoading(true);
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let tb = findCurrentMenu().key;

    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
      update: {
        deactive: _status,
        update_at: serverTime.data,
        user_id_update: userState.user_id,
      },
      table: tb,
      match: `id = '${e.id}'`,
    });

    if (res.data.ok) {
      await getQuery(tb, skipPage);

      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  const handleSave = async (e) => {
    setLoading(true);
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let tb = findCurrentMenu().key;
    let obj = formAsset.getFieldsValue();

    if (modalContent.id) {
      obj["user_id_update"] = userState.user_id;
      obj["update_at"] = serverTime.data;
    } else {
      obj["user_id_create"] = userState.user_id;
      obj["create_at"] = serverTime.data;
    }

    const res = modalContent.id
      ? await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
          update: obj,
          table: tb,
          match: `id = '${modalContent.id}'`,
        })
      : await postData(API_ENDPOINT + "/db/mis_logistic_nu/insert", {
          insert: obj,
          table: tb,
        });

    if (res.data.ok) {
      await getQuery(tb, skipPage);

      setDefaultForm();

      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
    setModalContent({ open: false });
  };

  const findCurrentMenu = () => {
    let cur = items;

    pathMenu.forEach((x) => {
      const filter = cur.filter((y) => y.key === x);

      if (filter[0].children) {
        cur = filter[0].children;
      } else {
        cur = filter[0];
      }
    });

    return cur || {};
  };

  const setDefaultForm = () => {
    formAsset.setFieldsValue({
      name: "",
      detail: "",
      type: "",
      total: 1,
    });
  };

  useEffect(() => {
    async function init() {
      setSkipPage({ limit: 50, skip: 0 });
      setTotalPage(50);

      let tb = findCurrentMenu().key;

      await getQuery(tb, { limit: 50, skip: 0 });
      const dataBorrow = await getQueryBorrow();

      if (tb === "asset") {
        setColumns([
          {
            title: "No.",
            dataIndex: "no",
            key: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
          },
          {
            title: "ชื่อ",
            dataIndex: "name",
            key: "name",
            align: "left",
            //   render: (text) => (
            //     <Input.TextArea defaultValue={text} autoSize={{ minRows: 1 }} />
            //   ),
            sorter: (a, b) => (a.name < b.name ? -1 : 1),
          },
          {
            title: "คำอธิบาย",
            dataIndex: "detail",
            key: "detail",
            align: "left",
            sorter: (a, b) => (a.detail < b.detail ? -1 : 1),
          },
          {
            title: "ประเภท",
            dataIndex: "type",
            key: "type",
            align: "center",
            render: (text) => {
              return text === "software" ? (
                <Tag color="warning">{text}</Tag>
              ) : (
                <Tag color="processing">{text}</Tag>
              );
            },
            sorter: (a, b) => (a.type < b.type ? -1 : 1),
          },
          {
            title: "จำนวน (ยืม/ทั้งหมด)",
            dataIndex: "total",
            key: "total",
            align: "right",
            render: (text, record) => {
              const fineBorrow = dataBorrow.find(
                (x) => x.asset_id === record.id
              );

              return (
                <div>
                  {fineBorrow?.total || 0}/{text}
                </div>
              );
            },
            sorter: (a, b) => (a.total < b.total ? -1 : 1),
          },
          {
            title: "",
            dataIndex: "deactive",
            key: "deactive",
            align: "center",
            render: (text, record) => {
              return (
                <div className="flex gap-7 p-2 justify-center">
                  {text ? (
                    <Switch
                      className="bg-gray-500"
                      checkedChildren="active"
                      unCheckedChildren="deactive"
                      defaultChecked={false}
                      onChange={() => handleDeactive(record, false)}
                    />
                  ) : (
                    <>
                      <EditOutlined
                        className="text-orange-500 cursor-pointer text-2xl"
                        onClick={() => {
                          formAsset.setFieldsValue({ ...record });
                          setModalContent({
                            open: true,
                            title: "Software & Hardware",
                            id: record.id,
                          });
                        }}
                      />
                      <DeleteOutlined
                        className="text-red-500 cursor-pointer text-2xl"
                        onClick={() =>
                          Swal.fire({
                            title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                            text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                            icon: "warning",
                            confirmButtonColor: "#de1a16",
                            showCancelButton: true,
                            confirmButtonText: "ยืนยัน",
                            cancelButtonText: "ยกเลิก",
                            preConfirm: () => handleDeactive(record, true),
                          })
                        }
                      />
                    </>
                  )}
                </div>
              );
            },
          },
        ]);
      }
    }

    init();
  }, []);

  return (
    <>
      <LoadingComponent open={loading} />
      <div className="w-full h-full flex flex-col gap-4">
        <Menu
          onClick={(e) => {
            console.log("click ", e);
            setCurrentMenu(e.key);
            setPathMenu(e.keyPath.reverse());
          }}
          selectedKeys={[currentMenu]}
          mode="horizontal"
          items={items}
        />

        <Card
          type="inner"
          title={findCurrentMenu().label}
          extra={
            <Button
              type="primary"
              className="bg-blue-500 flex justify-center items-center"
              onClick={() => {
                setDefaultForm();
                setModalContent({
                  open: true,
                  title: findCurrentMenu().label,
                });
              }}
            >
              <PlusCircleOutlined />
              เพิ่ม
            </Button>
          }
          headStyle={{ background: "#ccc" }}
          style={{
            boxShadow:
              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
          }}
          className="w-full"
        >
          <Table
            columns={columns.filter((x) => x)}
            dataSource={dataSource}
            className={
              "w-full rounded overflow-y-hidden overflow-x-scroll scrollbar-hide shadow-lg"
            }
            bordered
            pagination={{
              size: "small",
              total: totalPage,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              current: skipPage.skip + 1,
              pageSize: skipPage.limit,
              className: "px-4",
              onChange: (page, row) => onChangeSkip(page, row),
            }}
            locale={{
              triggerDesc: null,
              triggerAsc: null,
              cancelSort: null,
            }}
          />
        </Card>
      </div>

      <Modal
        title={modalContent.title}
        centered
        open={modalContent.open}
        // onOk={() => setOpen(false)}
        onCancel={() => setModalContent({ open: false })}
        width={1000}
        footer={null}
      >
        <Form layout={"vertical"} form={formAsset} onFinish={handleSave}>
          <Card
            bordered={true}
            type="inner"
            style={{
              boxShadow:
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}
          >
            <Form.Item
              label={`ชื่อ`}
              name={"name"}
              rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
            >
              <Input.TextArea placeholder={"ชื่อ"} autoSize={{ minRows: 1 }} />
            </Form.Item>
            <Form.Item
              label={`คำอธิบาย`}
              name={"detail"}
              //   rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
            >
              <Input.TextArea
                placeholder={"คำอธิบาย"}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
            <Form.Item
              label={`ประเภท`}
              name={"type"}
              rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
            >
              <Select
                options={[
                  {
                    value: "",
                    label: "กรุณาเลือก",
                    disabled: true,
                  },
                  {
                    value: "software",
                    label: "software",
                  },
                  {
                    value: "hardware",
                    label: "hardware",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={`จำนวน`}
              name={"total"}
              rules={[
                { required: true, message: "กรุณากรอก ${label}!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (Number(value) < 0) {
                      return Promise.reject(
                        new Error(`กรุณากรอกจำนวน ให้ถูกต้อง!`)
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type="number" defaultValue="1" min={1} step={1} />
            </Form.Item>
            <div className="flex justify-center items-center gap-2">
              <Button
                key="back"
                className="w-1/4"
                onClick={() => {
                  setModalContent({ open: false });
                }}
              >
                ยกเลิก
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                className="bg-blue-500 w-1/4"
                loading={loading}
              >
                บันทึก
              </Button>
            </div>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default SettingPage;
