import React, { useState } from "react";
import { Form, Input, Button, Card } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { API_ENDPOINT } from "../config";
import { logo_nobg } from "../image";
import LoadingComponent from "../components/loading";
// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { setAuthState } from "../store/slices/auth";

const SigninPage = () => {
  // const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    setLoading(true);

    let obj = {
      username: e.username,
      password: e.password,
    };

    try {
      const res = await axios.post(API_ENDPOINT + "/auth/signin", obj);

      if (res.data.ok) {
        if (res.data.message?.userdata) {
          localStorage.setItem(
            "userstate",
            JSON.stringify(res.data.message.userdata)
          );
          localStorage.setItem("token", JSON.stringify(res.data.message.token));

          // navigate("/");
          window.location.href = "/";
        } else {
          Swal.fire({
            text: res.data.message,
            icon: "error",
            showConfirmButton: false,
          });
        }
      } else {
        console.log("loginerr", res.data.message);
        Swal.fire({
          text: "มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง",
          icon: "error",
          showConfirmButton: false,
        });
      }
    } catch (err) {
      console.log("loginerr", err);
      Swal.fire({
        // title: "",
        text: "มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง",
        icon: "error",
        showConfirmButton: false,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <LoadingComponent open={loading} />
      <div className="m-12 w-full flex justify-center">
        <Card
          title={
            <div className="text-center font-bold text-2xl p-4 text-white">
              Management Information System
            </div>
          }
          // bordered={false}
          headStyle={{ backgroundColor: "#ff9b26" }}
          style={{ backgroundColor: "#f3b062" }}
          className="rounded-lg w-6/12"
        >
          <div className="flex justify-center gap-10 w-full">
            <Form
              form={form}
              layout="vertical"
              className="w-full flex flex-col justify-center"
              onFinish={onSubmit}
              autoComplete="off"
            >
              <Form.Item
                label={<div className="text-lg text-gray-700">Username</div>}
                name="username"
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="ชื่อผู้ใช้" className="text-lg" />
              </Form.Item>
              <Form.Item
                label={<div className="text-lg text-gray-700">Password</div>}
                name="password"
                rules={[{ required: true, message: "" }]}
              >
                <Input.Password placeholder="รหัสผ่าน" className="text-lg" />
              </Form.Item>
              <Form.Item className="text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-blue-500"
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
            <img src={logo_nobg} preview={"false"} width={400} />
          </div>
        </Card>
      </div>
    </>
  );
};

export default SigninPage;
