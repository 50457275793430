import React from "react";
import { HomeOutlined, LeftOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MENU_PATH } from "../config";
import { findByKey } from "../utils";

export default function BreadcrumbComponent() {
  const location = useLocation();
  const navigate = useNavigate();

  let pathnames = [];
  let locationPath = location.pathname.slice(1);
  const subPath = [
    ...new Set(
      []
        .concat(
          ...MENU_PATH.map((x) => (x.subpath ? Object.keys(x.subpath) : null))
        )
        .filter((x) => x)
    ),
  ];
  const mapPath = [...MENU_PATH.map((x) => x.path), ...subPath];

  mapPath.forEach((path) => {
    if (locationPath.includes(path)) {
      pathnames.push(path);
    }
  });

  // if (mapPath.includes(locationPath)) pathnames = [locationPath];
  // else pathnames = locationPath.split("/").filter((x) => x);

  return pathnames.length > 0 ? (
    <div className="pt-3 px-4 -mb-2 flex justify-between items-center text-bold">
      <a className="cursor-pointer text-sm text-blue-600" onClick={() => navigate(-1)}>
        <LeftOutlined />
        ย้อนกลับ
      </a>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={{ pathname: "/" }}>
                <HomeOutlined className="text-blue-600" />
              </Link>
            ),
          },

          ...pathnames.map((x, i) => {
            const routeTo = `/${pathnames.slice(0, i + 1).join("/")}`;
            const isLast = i === pathnames.length - 1;
            let pathlabel = MENU_PATH.filter((y) => y.path === x)?.[0]
              ?.menu;

            if (!pathlabel) {
              const filter = MENU_PATH.filter(
                (y) => y.path === pathnames[i - 1]
              )?.[0];

              pathlabel = findByKey(filter, x)?.menu;
            }

            return {
              title: isLast ? (
                pathlabel
              ) : (
                <Link
                  key={routeTo}
                  to={{ pathname: routeTo }}
                  onClick={() => navigate(routeTo)}
                >
                  <span className="text-blue-600">{pathlabel}</span>
                </Link>
              ),
            };
          }),
        ]}
      />
    </div>
  ) : null;
}
