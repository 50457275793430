import React, { useState } from "react";
import {
  UserOutlined,
  EditOutlined,
  LogoutOutlined,
  CopyrightOutlined,
  FundOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  ConfigProvider,
  Avatar,
  Modal,
  Button,
  Form,
  Input,
  Spin,
} from "antd";
import { logo_nobg } from "../image";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadcrumbComponent from "./breadcrumb";
import { API_ENDPOINT, USERDATA } from "../config";
import { postData } from "../utils";
import Swal from "sweetalert2";

export default function LayoutComponent({ children }) {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.auth.authUser) || USERDATA;

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const handleChangePass = async () => {
    const res = await postData(API_ENDPOINT + `/auth/changepass`, {
      id: userState.user_id,
      old_pass: form.getFieldValue("oldpassword"),
      new_pass: form.getFieldValue("newpassword"),
    });

    if (res.data.ok) {
      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      Swal.fire({
        text: res.data.message,
        showCloseButton: true,
        icon: "error",
        showConfirmButton: false,
      });
    }

    setAddLoading(true);
    setIsModalOpen(false);
    setAddLoading(false);
  };

  const handleCancle = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: <Link to={{ pathname: "/profile" }}>ข้อมูลส่วนตัว</Link>,
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: (
        <Link
          to={{ pathname: `/progressprofile/${userState.user_id}` }}
          target="_blank"
        >
          ผลงานของฉัน
        </Link>
      ),
      icon: <FundOutlined />,
    },
    {
      key: "3",
      label: <div onClick={() => setIsModalOpen(true)}>เปลี่ยนรหัสผ่าน</div>,
      icon: <EditOutlined />,
    },
    {
      key: "4",
      danger: true,
      label: (
        <div
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
        >
          ออกจากระบบ
        </div>
      ),
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <section className="flex flex-col min-h-0 justify-between w-full h-screen">
      <section className="flex min-h-0 h-full overflow-x-hidden">
        <div
          className={
            "w-full h-full p-0 flex flex-col min-h-0 bg-opacity-50 bg-gray-300"
          }
        >
          <header className="h-20 px-4 flex justify-between bg-gray-900 text-white font-bold">
            <div className="flex justify-center items-center">
              <img
                className="mx-auto h-14 w-auto"
                src={logo_nobg}
                alt="nav-logo"
              />
            </div>
            <div className="h-20 flex justify-center items-center font-medium text-center">
              <span className="text-lg">
                ระบบสารสนเทศเพื่อการบริหารจัดการคณะโลจิสติกส์และดิจิทัลซัพพลายเชน
                <br />
                <span className="text-sm">
                  Management Information System for NU-Logistics
                </span>
              </span>
            </div>
            <div className="flex justify-center items-center">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                className="cursor-pointer"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar
                    style={{
                      backgroundColor: "#fde3cf",
                      color: "#f56a00",
                    }}
                    size={{ xs: 40, sm: 40, md: 40, lg: 48, xl: 48, xxl: 48 }}
                  >
                    {userState?.eng_firstname?.charAt(0) +
                      userState?.eng_lastname?.charAt(0)}
                  </Avatar>
                </a>
              </Dropdown>

              <Modal
                title="เปลี่ยนรหัสผ่าน"
                open={isModalOpen}
                onCancel={handleCancle}
                footer={[]}
              >
                <Form
                  form={form}
                  className="ant-col-24 mt-10"
                  layout="vertical"
                  onFinish={handleChangePass}
                >
                  <div className="ant-col-24">
                    <Form.Item
                      label="รหัสผ่านปัจจุบันของคุณ"
                      name="oldpassword"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input.Password type="password" placeholder="ระบุ" />
                    </Form.Item>
                  </div>
                  <div className="ant-col-24">
                    <Form.Item
                      name="newpassword"
                      label="รหัสผ่านใหม่"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      hasFeedback
                      //   validateStatus="error"
                    >
                      <Input.Password placeholder="ระบุ" />
                    </Form.Item>
                  </div>
                  <div className="ant-col-24">
                    <Form.Item
                      name="confirmpassword"
                      label="ยืนยันรหัสผ่านใหม่"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newpassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The 2 passwords that you entered do not match !"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="ระบุ" />
                    </Form.Item>
                  </div>

                  <div className="flex gap-4 justify-end h-8 mt-10">
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "rgb(185 28 28)",
                        },
                      }}
                    >
                      <Button
                        type="primary"
                        className="bg-red-500 flex justify-center items-center w-28 rounded shadow-none"
                        onClick={handleCancle}
                      >
                        ยกเลิก
                      </Button>
                    </ConfigProvider>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "rgb(52, 144, 220)",
                        },
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={addLoading}
                        className="bg-blue-500 flex justify-center items-center min-w-28 rounded shadow-none"
                      >
                        บันทึก
                      </Button>
                    </ConfigProvider>
                  </div>
                </Form>
              </Modal>
            </div>
          </header>
          <BreadcrumbComponent />
          <div className="m-4 p-4 bg-white h-full overflow-y-scroll scrollbar-hide">
            {/* <div className="w-full h-full bg-white">{children}</div>  */}

            {children}
          </div>
        </div>
      </section>
      <footer className="font-medium p-6 h-20 flex gap-2 justify-center items-center bg-gray-900 text-white text-center">
        <CopyrightOutlined /> SununT-Dev Team, Naresuan University
      </footer>
    </section>
  );
}
