import React, { useEffect, useState } from "react";
import { Button, Card, Avatar, List, Tag } from "antd";
import { project_status, sick, relax, no_pictures } from "../image";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINT, MENU_PATH } from "../config";
import { downQualityImage, postData } from "../utils";
import LoadingComponent from "../components/loading";

const BookCardPage = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const locationPath = location.pathname.slice(1);
  const filterPath = MENU_PATH.filter((x) => x.path === locationPath)[0];

  const userState = useSelector((state) => state.auth.authUser);
  const imageProfile = localStorage.getItem("image_profile");

  const [image, setImage] = useState(imageProfile);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      setLoading(true);

      if (!imageProfile) {
        const res = await postData(
          API_ENDPOINT + "/db/mis_logistic_nu/select",
          {
            query: {
              table: "core_role",
              match: `user_id = '${userState.user_id}'`,
              projection: "image_profile",
            },
          }
        );

        if (res.data.ok) {
          if (
            res.data.message.length > 0 &&
            res.data.message[0]?.image_profile
          ) {
            const newImg = await downQualityImage(
              res.data.message[0].image_profile
            );

            localStorage.setItem("image_profile", newImg);

            setImage(newImg);
          } else {
            localStorage.setItem("image_profile", no_pictures);

            setImage(no_pictures);
          }
        } else {
          console.log(res.data.message);
        }
      }

      setLoading(false);
    }

    init();
  }, []);

  return (
    <>
      <LoadingComponent open={loading} />
      <div className="w-full flex gap-8">
        <div className="flex flex-col gap-4 items-center justify-center w-2/6">
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 200, xxl: 300 }}
            src={image}
            className="shadow-xl"
          />
        </div>
        <Card
          className="w-full"
          bordered={false}
          style={{
            boxShadow:
              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
          }}
        >
          <Tag className="w-full flex gap-4 p-4 text-xl shadow-lg bg-blue-500 border-none text-white">
            <label>ชื่อ - สกุล :</label>
            <div className="flex flex-col gap-2">
              <div>
                <label>
                  {userState.prefix_name} {userState.firstname}{" "}
                  {userState.middlename} {userState.lastname}
                </label>
              </div>
              <div>
                <label>
                  {userState.eng_prefix_name} {userState.eng_firstname}{" "}
                  {userState.eng_middlename} {userState.eng_lastname}
                </label>
              </div>
            </div>
          </Tag>
          <List
            className="my-4 shadow-xl p-4 rounded-md bg-gray-100"
            itemLayout="horizontal"
            dataSource={[
              {
                title: "เบอร์โทรศัพท์",
                description: userState.phone,
              },
              {
                title: "วัน/เดือน/ปีเกิด",
                description: userState.birthday,
                // .split("/")
                // .map((x, i) => {
                //   if (i === 2) {
                //     x = Number(x) - 543;
                //   }

                //   return x;
                // })
                // .join("/"),
              },
              {
                title: "บุคลากร",
                description: userState.type_name,
              },
              {
                title: "ตำแหน่ง",
                description: userState.position_name,
              },
              {
                title: "สถานภาพ",
                description: userState.status_name,
              },
              {
                title: "งาน",
                description: userState.dept_name,
              },
              {
                title: "อีเมล",
                description: userState.email,
              },
            ]}
            renderItem={(item, index) => (
              <List.Item
                style={{ borderBottom: "none", margin: 0, padding: 5 }}
              >
                <List.Item.Meta
                  title={
                    <label className="text-lg">
                      {item.title} : {item.description}
                    </label>
                  }
                />
              </List.Item>
            )}
          />

          <div className="w-full flex gap-4">
            {params.id !== "leave" ? (
              <Button
                type="primary"
                size="large"
                className="bg-blue-500 flex justify-center items-center py-5"
                icon={<img src={filterPath.image} style={{ width: "30px" }} />}
                onClick={() => navigate("form")}
              >
                {filterPath.subpath.form.menu}
              </Button>
            ) : (
              <>
                <Button
                  type="primary"
                  size="large"
                  className="bg-blue-500 flex justify-center items-center py-5"
                  icon={<img src={sick} style={{ width: "30px" }} />}
                  onClick={() => navigate("sick")}
                >
                  {filterPath.subpath.sick.menu}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="bg-blue-500 flex justify-center items-center py-5"
                  icon={<img src={relax} style={{ width: "30px" }} />}
                  onClick={() => navigate("relax")}
                >
                  {filterPath.subpath.relax.menu}
                </Button>
              </>
            )}

            <Button
              type="primary"
              size="large"
              className="bg-blue-500 flex justify-center items-center py-5"
              icon={<img src={project_status} style={{ width: "30px" }} />}
              onClick={() => navigate("follow")}
            >
              ติดตามสถานะ
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BookCardPage;
