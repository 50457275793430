import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// import './antd.css';
import SigninPage from "./pages/signin";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import HomePage from "./pages/home";
import LayoutComponent from "./components/layout";
import ProfilePage from "./pages/profile";
import AdminPage from "./pages/admin";
import WorkloadPage from "./pages/workload";
import BookingPage from "./pages/booking";
import BookCardPage from "./pages/bookcard";
import ProgressProfilePage from "./pages/progress";
import SettingPage from "./pages/setting";

const PrivateRoute = () => {
  const authToken = localStorage.getItem("token");

  return authToken ? <Outlet /> : <Navigate to="/login" />;
};

const PublicRoute = () => {
  const authToken = localStorage.getItem("token");

  return !authToken ? <Outlet /> : <Navigate to="/" />;
};

function App() {
  return (
    <div className="flex justify-center items-center h-screen">
      <Router>
        <Routes>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route
              exact
              path="/"
              element={
                <LayoutComponent>
                  <HomePage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/booking/:id"
              element={
                <LayoutComponent>
                  <BookCardPage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/booking/:id/:book"
              element={
                <LayoutComponent>
                  <BookingPage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/profile"
              element={
                <LayoutComponent>
                  <ProfilePage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/admin"
              element={
                <LayoutComponent>
                  <AdminPage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/workload"
              element={
                <LayoutComponent>
                  <WorkloadPage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/asset"
              element={
                <LayoutComponent>
                  <SettingPage />
                </LayoutComponent>
              }
            />
            <Route
              exact
              path="/setting"
              element={
                <LayoutComponent>
                  <SettingPage />
                </LayoutComponent>
              }
            />
          </Route>

          <Route path="/login" element={<PublicRoute />}>
            <Route exact path="/login" element={<SigninPage />} />
          </Route>

          <Route
            exact
            path="/progressprofile/:id"
            element={<ProgressProfilePage />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
