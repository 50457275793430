import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pickImage from "./image";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabun.ttf",
    bold: "THSarabun Bold.ttf",
    italics: "THSarabun Italic.ttf",
    bolditalics: "THSarabun BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

const FormCar = (r, _x, _y, _z) => {
  let user_signature = _x?.image_signature || null;
  let user_name = _x?.fullname_th || _x?.fullname_en || null;
  let user_position = _x?.position_name || null;

  let one_signature = _y?.image_signature || null;
  let one_name = _y?.fullname_th || _y?.fullname_en || null;
  let one_position = _y?.position_name || null;

  let two_signature = _z?.image_signature || null;
  let two_name = _z?.fullname_th || _z?.fullname_en || null;
  let two_position = _z?.position_name || null;

  let create_Start = new Date(r.create_at);
  let TH_datecreate_date = create_Start.toLocaleDateString("th-TH", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  let date_Start = new Date(r.time_start);
  let TH_dateStart_day = date_Start.toLocaleDateString("th-TH", {
    day: "numeric",
  });
  let TH_dateStart_month = date_Start.toLocaleDateString("th-TH", {
    month: "long",
  });
  let TH_dateStart_year = date_Start.toLocaleDateString("th-TH", {
    year: "numeric",
  });
  let TH_dateStart_time = date_Start.toLocaleDateString("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });

  let Start_year = TH_dateStart_year.substring(TH_dateStart_year.length - 4);
  let Start_time = TH_dateStart_time.substring(TH_dateStart_time.length - 5);

  let date_End = new Date(r.time_end);
  let TH_dateEnd_day = date_End.toLocaleDateString("th-TH", {
    day: "numeric",
  });
  let TH_dateEnd_month = date_End.toLocaleDateString("th-TH", {
    month: "long",
  });
  let TH_dateEnd_year = date_End.toLocaleDateString("th-TH", {
    year: "numeric",
  });
  let TH_dateEnd_time = date_End.toLocaleDateString("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });

  let End_year = TH_dateEnd_year.substring(TH_dateEnd_year.length - 4);
  let End_time = TH_dateEnd_time.substring(TH_dateEnd_time.length - 5);

  var dd = {
    info: {
      title: `แบบใบขออนุมัติใช้รถ${
        r.car_name === "HONDA" ? "จักรยานยนต์คณะฯ" : "ตู้คณะฯ"
      } ภายในจังหวัดพิษณุโลก`,
    },
    content: [
      {
        columns: [
          {
            image: "Krut",
            width: 60,
            margin: [0, 0, 0, 0],
            alignment: "left",
          },
          {
            image: "unchecKed",
            width: 10,
            margin: [310, 0, 0, 0],
            alignment: "left",
          },
          {
            text:
              r.travel === "ภายในจังหวัดพิษณุโลก"
                ? "ภายในจังหวัดพิษณุโลก"
                : "ภายนอกจังหวัดพิษณุโลก",
            width: 400,
            margin: [300, -2, 0, 0],
            alignment: "right",
          },
        ],
      },
      {
        text: "บันทึกข้อความ",
        style: "header",
        alignment: "center",
        margin: [0, -50, 0, 0],
      },
      {
        margin: [0, 30, 0, 0],
        columns: [
          { text: "ส่วนราชการ", alignment: "left", style: "bigger" },
          {
            text: " คณะโลจิสติกส์และดิจิทัลซัพพลายเชน สำนักงานเลขานุการ งาน............................................โทร.......................................................",
            alignment: "left",
            margin: [-210, 2, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: "ที่",
            alignment: "left",
            margin: [0, 0, 0, 0],
            style: "bigger",
          },
          {
            text: "อว 0603.31.01(.........)/ ...........................",
            width: "auto",
            alignment: "left",
            margin: [-170, 2, 0, 0],
          },
          {
            text: "วันที่",
            alignment: "left",
            margin: [30, 0, 0, 0],
            style: "bigger",
          },
          {
            text: "..........................................................",
            alignment: "left",
            margin: [-130, 2, 0, 0],
          },
        ],
      },
      r.status === "อนุมัติ"
        ? {
            text: `${r.create_at ? TH_datecreate_date : ""}`,
            margin: [250, -19, 0, 0],
            alignment: "left",
          }
        : "",
      {
        margin: [0, 10, 0, 0],
        columns: [
          { text: "เรื่อง", alignment: "left", style: "bigger" },
          {
            text: `ขออนุมัติใช้รถ${
              r.car_name === "HONDA"
                ? "จักรยานยนต์คณะฯ"
                : "ตู้คณะฯ พร้อมพนักงานขับรถ"
            }  (ทะเบียน ${r.car_registration})`,
            alignment: "left",
            margin: [-245, 2, 0, 0],
          },
        ],
      },
      {
        columns: [
          { text: "เรียน", alignment: "left", style: "bigger" },
          {
            text: `คณบดีคณะโลจิสติกส์และดิจิทัลซัพพลายเชน`,
            alignment: "left",
            margin: [-243, 2, 0, 0],
          },
        ],
      },
      {
        margin: [55, 0, 0, 0],

        table: {
          widths: [25, 120, 30, 105, 20, 105],
          headerRows: 1,
          body: [
            [
              { text: "ข้าพเจ้า", border: [false, false, false, false] },
              {
                text: `${r.name}`,
                margin: [0, 0, 0, -3],
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "ตำแหน่ง", border: [false, false, false, false] },
              {
                text: user_position || "-",

                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "งาน", border: [false, false, false, false] },
              {
                text: `${r.dept_name ? r.dept_name : "-"} `,

                border: [false, false, false, true],
                alignment: "center",
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [
            77,
            45,
            r.car_name === "HONDA" ? 120 : 90,
            r.car_name === "HONDA" ? 235 : 265,
          ],
          headerRows: 1,
          body: [
            [
              {
                text: "โทรศัพท์มือถือ/ที่ทำงาน",
                border: [false, false, false, false],
              },
              {
                text: `${r.phone ? r.phone : "-"}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              {
                text: `ขออนุมัติใช้รถ${
                  r.car_name === "HONDA" ? "จักรยานยนต์" : "ตู้"
                }คณะฯ ${r.travel === "ภายนอกจังหวัดพิษณุโลก" ? "เพื่อ" : ""}`,
                border: [false, false, false, false],
              },
              r.travel === "ภายนอกจังหวัดพิษณุโลก"
                ? {
                    text: `${r.description ? r.description : "-"}`,
                    alignment: "center",
                    border: [false, false, false, true],
                  }
                : {
                    text: "",
                    border: [false, false, false, false],
                  },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        table: {
          widths: [35, 80, 100, 200, 55],
          headerRows: 1,
          body: [
            r.travel === "ภายในจังหวัดพิษณุโลก"
              ? [
                  {
                    text: `รายละเอียดการขอใช้${
                      r.car_name === "HONDA" ? "จักรยานยนต์" : "ตู้"
                    }เพื่อ`,
                    border: [true, true, true, false],

                    colSpan: 5,
                    alignment: "left",
                    style: "bigger",
                  },
                  {},
                  {},
                  {},
                  {},
                ]
              : [
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                ],
            r.travel === "ภายในจังหวัดพิษณุโลก"
              ? [
                  {
                    text: `${r.description ? r.description : "-"}`,
                    margin: [0, 0, 0, -10],
                    border: [true, false, true, false],
                    colSpan: 5,
                    alignment: "center",
                  },
                  {},
                  {},
                  {},
                  {},
                ]
              : [
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                ],

            r.travel === "ภายในจังหวัดพิษณุโลก"
              ? [
                  {
                    text: "....................................................................................................................................................................................................................................................................",
                    border: [true, false, true, false],
                    colSpan: 5,
                  },
                  {},
                  {},
                  {},
                  {},
                ]
              : [
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                  { text: "", border: [false, false, false, false] },
                ],
            [
              {
                text: "ลำดับที่",

                alignment: "center",
                style: "bigger",
                border: [true, true, true, true],
              },
              {
                text: "วัน/เดือน/ปี",

                alignment: "center",
                style: "bigger",
                border: [true, true, true, true],
              },
              {
                text: "เวลา",

                alignment: "center",
                style: "bigger",
                border: [true, true, true, true],
              },
              {
                text: "สถานที่",

                alignment: "center",
                style: "bigger",
              },
              {
                text: "จำนวนผู้เดินทาง",

                alignment: "center",
                style: "bigger",
              },
            ],
            [
              "1",
              `${TH_dateStart_day} ${TH_dateStart_month} ${Start_year}\n${TH_dateEnd_day} ${TH_dateEnd_month} ${End_year}`,
              `${Start_time}\n${End_time}`,
              `${r.location ? r.location : ""}`,
              `${r.people}`,
            ],
            [{ text: "\n\n\n\n\n\n\n\n\n\n\n\n", colSpan: 5 }, {}, {}, {}, {}],
          ],
        },
      },

      {
        margin: [-20, -150, 0, 0],

        table: {
          widths: [118, 122, 118, 121],
          headerRows: 1,
          body: [
            [
              {
                image: r.agree === "true" ? "checKed" : "unchecKed",
                width: 10,
                alignment: "left",
                margin: [30, 3, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `ยินดีรับผิดชอบค่าใช้จ่ายทั้งหมด`,
                margin: [-70, 2, 0, 0],
                alignment: "left",
                border: [false, false, false, false],
              },
              {
                image: r.agree === "false" ? "checKed" : "unchecKed",
                margin: [-10, 3, 0, 0],
                alignment: "right",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: `ขออนุมัติยกเว้นค่าใช้จ่ายทั้งหมด`,
                margin: [0, 2, 0, 0],
                alignment: "right",
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 2 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 1 } };
          },
        },
      },

      {
        margin: [40, -5, 0, 0],
        alignment: "right",
        table: {
          widths: [300, 147],
          headerRows: 1,
          body: [
            [
              {
                text: "เนื่องจาก",
                margin: [0, 2, 0, 0],
                alignment: "right",
                border: [false, false, false, false],
              },
              {
                text: `${r.agree_detail ? r.agree_detail : "-"}`,
                margin: [0, 2, 0, 0],
                alignment: "center",
                border: [false, false, false, true],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 2 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 300 } };
          },
        },
      },

      {
        margin: [300, 0, 0, 0],
        table: {
          widths: [15, 100, r.car_name === "HONDA" ? 62 : 40],
          headerRows: 1,
          body: [
            [
              {
                image: "one",
                width: 15,
                margin: [0, 30, 0, 0],
                border: [false, false, false, false],
              },
              user_signature
                ? {
                    image: `${user_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 10, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : {
                    text: "",
                    width: 100,
                    height: 30,
                    margin: [50, 30, 50, 0],
                    border: [false, false, false, true],
                  },
              {
                text: `${
                  r.car_name === "HONDA"
                    ? "ผู้ขอใช้จักรยานยนต์"
                    : "ผู้ขอใช้รถตู้"
                }`,
                margin: [0, 30, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },

      {
        margin: [325, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(.................................................)`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [325, -30, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `${!user_name ? "" : user_name}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      r.travel === "ภายนอกจังหวัดพิษณุโลก"
        ? {
            margin: [0, r.travel === "ภายนอกจังหวัดพิษณุโลก" ? -100 : 0, 0, 0],
            columns: [
              {
                text: "(และดำเนินการเอกสารเกี่ยวกับการเดินทางและเบิกจ่ายทั้งหมด)",
                margin: [10, 0, 0, 10],
              },
            ],
          }
        : {
            margin: [0, r.travel === "ภายนอกจังหวัดพิษณุโลก" ? -100 : 0, 0, 0],
            columns: [
              {
                text: "",
                margin: [10, 0, 0, 10],
              },
            ],
          },

      r.travel === "ภายนอกจังหวัดพิษณุโลก"
        ? {
            columns: [
              {
                image: r.fuel_cost === "true" ? "checKed" : "unchecKed",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              { text: "ค่าน้ำมันเชื้อเพลิง", margin: [10, 0, 0, 0] },
            ],
          }
        : {
            columns: [
              {
                text: "",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              { text: "", margin: [10, 0, 0, 0] },
            ],
          },
      r.travel === "ภายนอกจังหวัดพิษณุโลก"
        ? {
            columns: [
              {
                image: r.driver_cost === "true" ? "checKed" : "unchecKed",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              {
                text: "ค่าเบี้ยเลี้ยงพนักงานขับรถพร้อมเงินสมทบ",
                margin: [10, 0, 0, 0],
              },
            ],
          }
        : {
            columns: [
              {
                text: "",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              {
                text: "",
                margin: [10, 0, 0, 0],
              },
            ],
          },
      r.travel === "ภายนอกจังหวัดพิษณุโลก"
        ? {
            columns: [
              {
                image:
                  r.accommodation_cost === "true" ? "checKed" : "unchecKed",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              { text: "ค่าที่พัก", margin: [10, 0, 0, 0] },
            ],
          }
        : {
            columns: [
              {
                text: "",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              { text: "", margin: [10, 0, 0, 0] },
            ],
          },
      r.travel === "ภายนอกจังหวัดพิษณุโลก"
        ? {
            columns: [
              {
                image: r.other_expenses === "true" ? "checKed" : "unchecKed",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              {
                text: `ค่าใช้จ่ายอื่นๆ..........${
                  r.other_detail ? r.other_detail : ""
                }..........`,
                margin: [10, 0, 0, 0],
              },
            ],
          }
        : {
            columns: [
              {
                text: "",
                width: 10,
                margin: [20, 0, 0, 0],
              },
              {
                text: "",
                margin: [10, 0, 0, 0],
              },
            ],
          },
      {
        margin: [0, r.travel === "ภายนอกจังหวัดพิษณุโลก" ? 60 : 30, 0, 0],

        table: {
          widths: [220],
          headerRows: 1,
          body: [
            [
              {
                text: "เรียน คณบดีคณะโลจิสติกส์และดิจิทัลซัพพลายเชน",
                margin: [0, 0, 0, 0],
                style: "bigger",
                border: [false, false, false, false],
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },

      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [15, 80],
          headerRows: 1,
          body: [
            [
              {
                image: "two",
                width: 15,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: "เพื่อโปรดพิจารณาอนุมัติ",
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              one_signature
                ? {
                    image: `${one_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : {
                    text: "",
                    width: 100,
                    height: 30,
                    margin: [50, 30, 50, 0],
                    border: [false, false, false, true],
                  },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(${!one_name ? "" : one_name})`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `${!one_position ? "" : one_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },

      {
        margin: [300, -100, 0, 0],

        table: {
          widths: [15, 25, 15, 20, 15, 30],
          headerRows: 1,
          body: [
            [
              {
                image: "three",
                width: 15,
                margin: [0, -20, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: "คำสั่ง",
                style: "bigger",
                margin: [0, -20, 0, 0],
                border: [false, false, false, false],
              },
              {
                image: r.status === "อนุมัติ" ? "checKedCicle" : "cicle",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: "อนุมัติ",
                border: [false, false, false, false],
              },
              {
                image: r.status === "ไม่อนุมัติ" ? "checKedCicle" : "cicle",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: "ไม่อนุมัติ",
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [340, 0, 0, 0],

        table: {
          widths: [25, 100],
          headerRows: 1,
          body: [
            [
              {
                text: "(ลงชื่อ)",
                margin: [0, 25, 0, 0],
                border: [false, false, false, false],
              },
              two_signature
                ? {
                    image: `${two_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [370, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(${!two_name ? "" : two_name})`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [310, 0, 0, 0],

        table: {
          widths: [220],
          headerRows: 1,
          body: [
            [
              {
                text: `ตำแหน่ง ${!two_position ? "" : two_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      bigger: {
        fontSize: 15,
        bold: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      font: "THSarabunNew",
    },
    images: {
      checKedCicle: pickImage.checKedCicle,
      three: pickImage.three,
      two: pickImage.two,
      one: pickImage.one,
      checKed: pickImage.checKed,
      unchecKed: pickImage.unchecKed,
      cicle: pickImage.cicle,
      Krut: pickImage.Krut,
    },
  };
  pdfMake.createPdf(dd).open();
};

export { FormCar };
