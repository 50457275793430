import { createSlice } from "@reduxjs/toolkit";
import { USERDATA, TOKEN } from "../../config";

var initialState = {
  authState: true,
  authUser: USERDATA,
  authToken: TOKEN,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState(state, action) {
      state.authUser = action.payload.authUser;
      state.authToken = action.payload.authToken;
    },
  },
});

export const { setAuthState } = authSlice.actions;
export const selectAuthState = (state) => state.auth.authState;
export const selectAuthUser = (state) => state.auth.authUser;
export default authSlice.reducer;
