import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Select,
  Card,
  Input,
  Row,
  Col,
  Avatar,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  API_ENDPOINT,
  BLOOD_TYPE,
  DISABILITY,
  GENDER,
  MARITAL_STATUS,
  PREFIX_NAME,
} from "../config/index";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";
import { downQualityImage, postData, toBase64 } from "../utils";
import LoadingComponent from "../components/loading";
import { no_pictures } from "../image";

// dayjs.extend(customParseFormat);
// const dateFormat = "DD/MM/YYYY";

const ProfilePage = () => {
  const userState = useSelector((state) => state.auth.authUser);

  const [formProfile] = Form.useForm();

  const [imgProfile, setProfile] = useState();
  const [imgSignature, setSignature] = useState();
  const [district, setDistrict] = useState([]);
  const [province, setProvince] = useState([]);
  const [parish, setParish] = useState([]);
  const [contractType, setContractType] = useState([]);
  const [managementPosition, setManagementPosition] = useState([]);
  const [dept, setDept] = useState([]);
  const [position, setPosition] = useState([]);
  const [type, setType] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleUploadProfile = async (info) => {
    if (["image/png", "image/jpeg"].includes(info.file.type)) {
      const base64 = await toBase64(info.file);
      const newImg = await downQualityImage(base64);

      setProfile(newImg);
    } else {
      message.error("ไม่รองรับนามสกุลไฟล์นี้");
    }
  };

  const handleUploadSignature = async (info) => {
    if (["image/png", "image/jpeg"].includes(info.file.type)) {
      const base64 = await toBase64(info.file);
      const newImg = await downQualityImage(base64);

      setSignature(newImg);
    } else {
      message.error("ไม่รองรับนามสกุลไฟล์นี้");
    }
  };

  const getProvince = async () => {
    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/select", {
      query: { projection: "*", table: "provinces" },
    });

    if (res.data.ok) {
      setProvince(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const getAmphures = async (e) => {
    let obj = {
      projection: "*",
      table: "amphures",
    };

    if (e) obj.match = `province_id = ${e}`;

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: obj,
    });

    if (res.data.ok) {
      setDistrict(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const getDistrict = async (e) => {
    let obj = {
      projection: "*",
      table: "districts",
    };

    if (e) obj.match = `amphure_id = ${e}`;

    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/select", {
      query: obj,
    });

    if (res.data.ok) {
      setParish(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const getZipCode = (e) => {
    let zipcode = parish.filter((x) => x.id === e)?.[0]?.zip_code;

    if (zipcode) {
      let allValues = formProfile.getFieldsValue();
      allValues.zip_code = zipcode;

      formProfile.setFieldsValue(allValues);
    }
  };

  const getPersonal = async (id) => {
    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/select", {
      query: { match: `user_id='${id}'`, table: "core_role" },
    });

    if (res.data.ok && res.data.message.length > 0) {
      let obj = res.data.message[0];
      // obj.birthday = dayjs(obj.birthday, dateFormat).subtract(543, "year");
      // obj.come_work = dayjs(obj.come_work, dateFormat).subtract(543, "year");

      formProfile.setFieldsValue(obj);
      setProfile(obj.image_profile);
      setSignature(obj.image_signature);

      return obj;
    } else {
      console.log(res.data.message);
    }
  };

  const getQuery = async (_tb, _cb) => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: { projection: `*`, table: _tb },
    });

    if (res.data.ok) {
      _cb(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  // const getContractTypeID = async () => {
  //   const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
  //     query: { projection: `*`, table: "contract_type" },
  //   });
  //   if (res.data.ok) {
  //     setContractType(res.data.message);
  //   } else {
  //     console.log(res.data.message);
  //   }
  // };

  // const getType = async () => {
  //   let res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
  //     query: { projection: `*`, table: "type" },
  //   });
  //   if (res.data.ok) {
  //     setType(res.data.message);
  //   } else {
  //     console.log(res.data.message);
  //   }
  // };

  // const getPosition = async () => {
  //   let res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
  //     query: { projection: `*`, table: "position" },
  //   });
  //   if (res.data.ok) {
  //     setPosition(res.data.message);
  //   } else {
  //     console.log(res.data.message);
  //   }
  // };

  // const getManagementPosition = async () => {
  //   const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
  //     query: { projection: `*`, table: "management_position" },
  //   });
  //   if (res.data.ok) {
  //     setManagementPosition(res.data.message);
  //   } else {
  //     console.log(res.data.message);
  //   }
  // };

  // const getDept = async () => {
  //   const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
  //     query: { projection: `*`, table: "dept" },
  //   });
  //   if (res.data.ok) {
  //     setDept(res.data.message);
  //   } else {
  //     console.log(res.data.message);
  //   }
  // };

  const handleSave = async () => {
    setLoading(true);

    let obj = formProfile.getFieldsValue();
    // obj.birthday = dayjs(obj.birthday, dateFormat)
    //   .add(543, "year")
    //   .format(dateFormat);
    // obj.come_work = dayjs(obj.come_work, dateFormat)
    //   .add(543, "year")
    //   .format(dateFormat);

    if (imgProfile) {
      obj.image_profile = imgProfile;

      localStorage.setItem("image_profile", imgProfile);
    } else {
      localStorage.setItem("image_profile", no_pictures);
    }

    if (imgSignature) {
      obj.image_signature = imgSignature;
    }

    const res = await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
      update: obj,
      match: `user_id='${userState.user_id}'`,
      table: "core_role",
    });

    if (res.data.ok) {
      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);

      Swal.fire({
        title: "System Error !",
        text: "Please contact your administrator.",
        showCloseButton: true,
        icon: "error",
        showConfirmButton: false,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    async function init() {
      setLoading(true);
      const obj = await getPersonal(userState.user_id);
      await getProvince();
      await getAmphures(obj.province_id);
      await getDistrict(obj.district_id);
      await getQuery("contract_type", setContractType);
      await getQuery("type", setType);
      await getQuery("position", setPosition);
      await getQuery("management_position", setManagementPosition);
      await getQuery("dept", setDept);
      // await getContractTypeID();
      // await getType();
      // await getDept();
      // await getPosition();
      // await getManagementPosition();
      setLoading(false);
    }

    init();
  }, []);

  return (
    <>
      <LoadingComponent open={loading} />
      <div className="md:max-w-full sm:max-w-lg min-h-full flex flex-col gap-8">
        <Form
          form={formProfile}
          //   labelCol={{ span: 4 }}
          //   wrapperCol={{ span: 14 }}
          layout="vertical"
          className="flex flex-col gap-8"
          onFinish={(e) => handleSave(e)}
        >
          <div className="w-full flex gap-8">
            <div className="flex flex-col gap-4 items-center justify-center w-2/6">
              <Avatar
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 200, xxl: 300 }}
                src={imgProfile}
                className="shadow-xl"
              />
              <Upload
                onChange={handleUploadProfile}
                listType="picture"
                beforeUpload={() => false}
                onPreview={() => false}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>SELECT NEW PROFILE</Button>
              </Upload>
            </div>
            <Card
              className="w-full"
              title="ข้อมูลส่วนตัว"
              bordered={false}
              headStyle={{ background: "#ccc" }}
              style={{
                boxShadow:
                  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
              }}
            >
              <Row>
                <Col span={8} className="p-2">
                  <Form.Item label="ชื่อผู้ใช้" name="user_name">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={8} className="p-2">
                  <Form.Item label="เลขบัตรประจำตัวประชาชน" name="card_number">
                    <Input placeholder="xxxxxxxxxxxxx" maxLength={13} />
                  </Form.Item>
                </Col>
                <Col span={8} className="p-2">
                  <Form.Item
                    label="เพศ"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก เพศ!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...GENDER,
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={3} className="p-2">
                  <Form.Item
                    label="คำนำหน้า"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก ชื่อ!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...PREFIX_NAME.th,
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} className="p-2">
                  <Form.Item label="ชื่อ" name="firstname">
                    <Input placeholder="ชื่อ" disabled />
                  </Form.Item>
                </Col>
                <Col span={7} className="p-2">
                  <Form.Item label="ชื่อกลาง" name="middlename">
                    <Input placeholder="ชื่อกลาง" disabled defaultValue="" />
                  </Form.Item>
                </Col>
                <Col span={7} className="p-2">
                  <Form.Item label="นามสกุล" name="lastname">
                    <Input placeholder="นามสกุล" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={3} className="p-2">
                  <Form.Item label="คำนำหน้า" name="eng_title">
                    <Select
                      defaultValue=""
                      disabled
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...PREFIX_NAME.en,
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} className="p-2">
                  <Form.Item label="ชื่อภาษาอังกฤษ" name="eng_firstname">
                    <Input placeholder="ชื่อภาษาอังกฤษ" disabled />
                  </Form.Item>
                </Col>
                <Col span={7} className="p-2">
                  <Form.Item label="ชื่อกลางภาษาอังกฤษ" name="eng_middlename">
                    <Input
                      placeholder="ชื่อกลางภาษาอังกฤษ"
                      disabled
                      defaultValue=""
                    />
                  </Form.Item>
                </Col>
                <Col span={7} className="p-2">
                  <Form.Item label="นามสกุลภาษาอังกฤษ" name="eng_lastname">
                    <Input placeholder="นามสกุลชื่อภาษาอังกฤษ" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="วันเกิด (ว/ด/ป)"
                    name="birthday"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก วันเดือนปีเกิด!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full"
                      // value={null}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="หมู่เลือด"
                    name="blood_type"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก หมู่เลือด!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...BLOOD_TYPE,
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="สัญชาติ"
                    name="nationality"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก สัญชาติ!",
                      },
                    ]}
                  >
                    <Input placeholder="สัญชาติ" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="ศาสนา"
                    name="religion"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก ศาสนา!",
                      },
                    ]}
                  >
                    <Input placeholder="ศาสนา" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="สถานภาพการสมรส"
                    name="marital_status"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก ชื่อ!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...MARITAL_STATUS,
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="ความพิการ"
                    name="disability"
                    rules={[{ required: true }]}
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...DISABILITY,
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="w-full">
            <Card
              className="w-full"
              title="ข้อมูลที่อยู่ที่สามารถติดต่อได้"
              bordered={false}
              headStyle={{ background: "#ccc" }}
              style={{
                boxShadow:
                  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
              }}
            >
              <Row>
                <Col span={2} className="p-2">
                  <Form.Item
                    label="บ้านเลขที่"
                    name="house_number"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก บ้านเลขที่!",
                      },
                    ]}
                  >
                    <Input placeholder="บ้านเลขที่" />
                  </Form.Item>
                </Col>
                <Col span={2} className="p-2">
                  <Form.Item
                    label="หมู่"
                    name="moo"
                    rules={[{ required: true, message: "กรุณากรอก หมู่!" }]}
                  >
                    <Input placeholder="หมู่" />
                  </Form.Item>
                </Col>
                <Col span={3} className="p-2">
                  <Form.Item label="ถนน" name="street">
                    <Input placeholder="ถนน" />
                  </Form.Item>
                </Col>
                <Col span={2} className="p-2">
                  <Form.Item label="ตรอก" name="alley">
                    <Input placeholder="ตรอก" />
                  </Form.Item>
                </Col>
                <Col span={3} className="p-2">
                  <Form.Item label="ซอย" name="alleyway">
                    <Input placeholder="ซอย" />
                  </Form.Item>
                </Col>
                <Col span={3} className="p-2">
                  <Form.Item
                    label="จังหวัด"
                    name="province_id"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(e) => getDistrict(e)}
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...province.map((x) => ({
                          label: x.name_th,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={3} className="p-2">
                  <Form.Item
                    label="อำเภอ"
                    name="district_id"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(e) => getDistrict(e)}
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...district.map((x) => ({
                          label: x.name_th,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={3} className="p-2">
                  <Form.Item
                    label="ตำบล"
                    name="parish_id"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(e) => getZipCode(e)}
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...parish.map((x) => ({
                          label: x.name_th,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={3} className="p-2">
                  <Form.Item
                    label="รหัสไปรษณีย์"
                    name="zip_code"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก รหัสไปรษณีย์!",
                      },
                    ]}
                  >
                    <Input placeholder="รหัสไปรษณีย์" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={5} className="p-2">
                  <Form.Item
                    label="เบอร์โทรศัพท์มือถือ"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก เบอร์โทรศัพท์มือถือ!",
                      },
                    ]}
                  >
                    <Input placeholder="เบอร์โทรศัพท์มือถือ" />
                  </Form.Item>
                </Col>
                <Col span={5} className="p-2">
                  <Form.Item label="เบอร์โทรศัพท์ที่ทำงาน" name="work_phone">
                    <Input placeholder="เบอร์โทรศัพท์ที่ทำงาน" />
                  </Form.Item>
                </Col>
                <Col span={6} className="p-2">
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item label="Facebook" name="facebook">
                    <Input placeholder="Facebook" defaultValue="" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item label="Line" name="line">
                    <Input placeholder="Line" defaultValue="" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="w-full">
            <Card
              className="w-full"
              title="ข้อมูลตำแหน่งการทำงาน"
              bordered={false}
              headStyle={{ background: "#ccc" }}
              style={{
                boxShadow:
                  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
              }}
            >
              <Row>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="ตำแหน่งงานบริหาร"
                    name="management_position_id"
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...managementPosition.map((x) => ({
                          label: x.management_position_name,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="ตำแหน่งวิชาการ/ตำแหน่งงาน"
                    name="position_id"
                  >
                    <Select
                      defaultValue=""
                      disabled
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...position.map((x) => ({
                          label: x.position_name,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item label="หน่วยงานย่อย" name="dept_id">
                    <Select
                      defaultValue=""
                      disabled={formProfile.getFieldValue("dept_id")}
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...dept.map((x) => ({
                          label: x.dept_name,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item label="สาขาวิชา" name="major">
                    <Input placeholder="สาขาวิชา" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="คณะ"
                    name="faculty"
                    rules={[{ required: true, message: "กรุณากรอก คณะ!" }]}
                  >
                    <Input placeholder="คณะ" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="ประเภทสัญญา"
                    name="contract_type_id"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก ประเภทสัญญา!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...contractType.map((x) => ({
                          label: x.contract_name,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} className="p-2">
                  <Form.Item label="ความชำนาญในสายงาน" name="expertise">
                    <Input placeholder="ความชำนาญในสายงาน" defaultValue="" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="เลขที่ตำแหน่ง"
                    name="position_number"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก เลขที่ตำแหน่ง!",
                      },
                    ]}
                  >
                    <Input placeholder="เลขที่ตำแหน่ง" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="เงินเดือนแรกเข้าที่บรรจุ"
                    name="first_salary"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก เงินเดือนแรกเข้าที่บรรจุ!",
                      },
                    ]}
                  >
                    <Input placeholder="เงินเดือนแรกเข้าที่บรรจุ" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="เงินเดือนปัจจุบัน"
                    name="current_salary"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก เงินเดือนปัจจุบัน!",
                      },
                    ]}
                  >
                    <Input placeholder="เงินเดือนปัจจุบัน" />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item label="ประเภทบุคคลากร" name="type_id">
                    <Select
                      defaultValue=""
                      disabled
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...type.map((x) => ({
                          label: x.type_name,
                          value: x.id,
                        })),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className="p-2">
                  <Form.Item
                    label="วันเข้าทำงาน (ว/ด/ป)"
                    name="come_work"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก วันเข้าทำงาน!",
                      },
                    ]}
                  >
                    <Input
                      className="w-full"
                      // value={null}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="w-full flex items-center justify-center">
            <div className="flex flex-col gap-4 items-center justify-center w-2/6">
              <Avatar
                shape="square"
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 200, xxl: 300 }}
                src={imgSignature}
                className="shadow-xl"
              />
              <Upload
                onChange={handleUploadSignature}
                listType="picture"
                beforeUpload={() => false}
                onPreview={() => false}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>SELECT NEW SIGNATURE</Button>
              </Upload>
            </div>
          </div>

          <div className="text-right">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-blue-500 h-10 w-1/3"
            >
              บันทึก
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProfilePage;
