import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuState: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    addMenuState(state, action) {
      state.menuState.push(action.payload);
    },
    removeMenuState(state, action) {
      for (let i = 0; i < state.menuState.length; i++) {
        let match = state.menuState[i] !== action.payload;

        if (match) {
          state.menuState.pop();
        } else break;
      }
    },
    clearMenuState(state, action) {
      state.menuState = [];
    },
  },
});

export const { addMenuState, removeMenuState, clearMenuState } =
  menuSlice.actions;
export default menuSlice.reducer;
