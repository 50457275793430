import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Select,
  Card,
  Input,
  Row,
  Col,
  DatePicker,
  Avatar,
  Upload,
  message,
  Space,
  Modal,
  Radio,
  Image,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PoweroffOutlined,
  UploadOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Link, Redirect } from "react-router-dom";
import {
  API_ENDPOINT,
  AUTH,
  BLOOD_TYPE,
  DISABILITY,
  GENDER,
  MARITAL_STATUS,
  PREFIX_NAME,
  USERDATA,
  WORKLOAD,
} from "../config/index";
import { admin_1, job_search, leasing, box, meeting, research } from "../image";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  base64ToBlob,
  cloneData,
  formatMoney,
  getData,
  onPreview,
  postData,
  toBase64,
} from "../utils";
import SelectTableComponent from "../components/selecttable";
import moment from "moment";
import LoadingComponent from "../components/loading";

const listType = {
  expertise: {
    value: "ความเชี่ยวชาญ",
    label: "ความเชี่ยวชาญ",
    form: 2,
  },
  study: {
    value: "การศึกษา",
    label: "การศึกษา",
    form: 0,
  },
  print: {
    value: "บทความวิจัยวิชาการที่ตีพิมพ์",
    label: "บทความวิจัยวิชาการที่ตีพิมพ์",
    form: 1,
  },
  offer: {
    value: "บทความวิจัยวิชาการที่นำเสนอในงานประชุมวิชาการ",
    label: "บทความวิจัยวิชาการที่นำเสนอในงานประชุมวิชาการ",
    form: 1,
  },
  director: {
    value: "กรรมการวิชาการ/กรรมการวิชาชีพ/กรรมการวิทยานิพนธ์ ภายนอกสถาบัน",
    label: "กรรมการวิชาการ/กรรมการวิชาชีพ/กรรมการวิทยานิพนธ์ ภายนอกสถาบัน",
    form: 1,
  },
  reward: {
    value: "รางวัล",
    label: "รางวัล",
    form: 1,
  },
  train: {
    value: "การอบรม",
    label: "การอบรม",
    form: 1,
  },
  personal_work: {
    value: "ผลงาน",
    label: "ผลงาน",
    form: 1,
  },
  research: {
    value: "โครงการวิจัย",
    label: "โครงการวิจัย",
    form: 3,
  },
  // right_: {
  //   value: "สิทธิบัตร/อนุสิทธิบัตร",
  //   label: "สิทธิบัตร/อนุสิทธิบัตร",
  //   form: 1,
  // },
  // copyright: {
  //   value: "ลิขสิทธิ์",
  //   label: "ลิขสิทธิ์",
  //   form: 1,
  // },
  // artifact: {
  //   value: "สิ่งประดิษฐ์",
  //   label: "สิ่งประดิษฐ์",
  //   form: 1,
  // },
  // book: {
  //   value: "หนังสือแต่ง",
  //   label: "หนังสือแต่ง",
  //   form: 1,
  // },
  // certificate: {
  //   value: "ใบประกาศนีย์บัตร/หนังสือรับรอง",
  //   label: "ใบประกาศนีย์บัตร/หนังสือรับรอง",
  //   form: 1,
  // },
  // attention: {
  //   value: "ความสนใจ",
  //   label: "ความสนใจ",
  //   form: 2,
  // },
};

let personalWork = [
  "สิทธิบัตร/อนุสิทธิบัตร",
  "ลิขสิทธิ์",
  "สิ่งประดิษฐ์",
  "หนังสือแต่ง",
  "ใบประกาศนีย์บัตร/หนังสือรับรอง",
];

const WorkloadPage = () => {
  const userState = useSelector((state) => state.auth.authUser);

  const [formWorkLoad] = Form.useForm();

  const [selectState, setSelectState] = useState("expertise");
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [skipPage, setSkipPage] = useState({
    limit: 50,
    skip: 0,
  });
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({
    open: false,
    title: "",
  });
  const [fileList, setFileList] = useState([]);
  const [radioOpt, setRadioOpt] = useState("เพิ่มจากระบบ");
  const [dataS, setDataS] = useState([]);

  const getQuery = async (_key, { skip, limit }) => {
    setLoading(true);

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: _key,
        match: `user_id='${userState.user_id}'`,
        sort: "create_at DESC",
        limit: limit,
        offset: limit * skip,
        projection: "*",
      },
      count: {
        projection: "*",
        match: `user_id='${userState.user_id}'`,
        table: _key,
      },
    });

    if (res.data.ok) {
      let { data, total } = res.data.message;
      let num = limit * skip + 1;

      const result = data.map((x) => {
        x.no = num++;
        // x.time_start = moment(Number(x.time_start)).format("YYYY/MM/DD HH:mm");
        // x.time_end = moment(Number(x.time_end)).format("YYYY/MM/DD HH:mm");
        x.create_at = moment(Number(x.create_at)).format("YYYY-MM-DD");
        // x.sucess_time = x.sucess_time
        //   ? moment(new Date(Number(x.sucess_time)).getTime()).format(
        //       "YYYY-MM-DD"
        //     )
        //   : null;
        // x.status =
        //   x.status === "2"
        //     ? "อยู่ระหว่างดำเนินการ"
        //     : x.status === "1"
        //     ? "อนุมัติ"
        //     : "ไม่อนุมัติ";

        return x;
      });

      if (["expertise", "attention"].includes(selectState)) {
        await getQueryS();
      }

      setDataSource(result);
      setTotalPage(total);
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
  };

  const getQueryS = async () => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "s_" + selectState,
        projection: "DISTINCT " + selectState + " AS name",
        sort: selectState + " ASC",
      },
    });

    if (res.data.ok) {
      setDataS(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const onChangeSkip = async (page, row) => {
    let e = { skip: page - 1, limit: row };

    setSkipPage(e);
    await getQuery(selectState, e);
  };

  const handleSave = async (e) => {
    setLoading(true);

    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let val = formWorkLoad.getFieldsValue();

    if (listType[selectState].form === 0) {
      val.year = moment(val.year.$d).year();
    } else if (listType[selectState].form === 1) {
      val.year = moment(val.year.$d).year();
      val.file = JSON.stringify(
        fileList.map((x) => ({ name: x.name, file: x.thumbUrl }))
      );
    } else if (listType[selectState].form === 2) {
      delete val.from;
    } else {
      val.year_start = moment(val.year[0].$d).year();
      val.year_end = moment(val.year[1].$d).year();
      delete val.year;
    }

    let obj = {
      user_id: userState.user_id,
      create_at: serverTime.data,
      update_at: serverTime.data,
      ...val,
    };

    const res = modalContent.id
      ? await postData(API_ENDPOINT + "/db/mis_logistic_nu/update", {
          update: obj,
          table: selectState,
          match: "id = " + modalContent.id,
        })
      : await postData(API_ENDPOINT + "/db/mis_logistic_nu/insert", {
          insert: obj,
          table: selectState,
        });

    if (res.data.ok) {
      if (
        ["expertise", "attention"].includes(selectState) &&
        formWorkLoad.getFieldValue("from") === "เพิ่มด้วยตนเอง"
      ) {
        delete obj.create_at;
        delete obj.update_at;

        const resS = await postData(
          API_ENDPOINT + "/db/mis_logistic_nu/insert",
          {
            insert: obj,
            table: "s_" + selectState,
          }
        );

        console.log(resS.data.message);
      }

      await getQuery(selectState, skipPage);

      setDefaultForm();

      Swal.fire({
        text: "บันทึกสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: true,
      });
    } else {
      console.log(res.data.message);
    }

    setLoading(false);
    setModalContent({ open: false });
  };

  const handleDelete = async (e) => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/delete`, {
      remove: {
        match: `id = ${e.id}`,
      },
      table: selectState,
    });

    if (res.data.ok) {
      await getQuery(selectState, skipPage);

      Swal.fire({
        text: "ลบข้อมูลสำเร็จ !",
        showCloseButton: true,
        icon: "success",
        showConfirmButton: false,
      });
    } else {
      console.log(res.data.message);

      Swal.fire({
        title: "ไม่มีข้อมูลในระบบ !",
        showCloseButton: true,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const setDefaultForm = () => {
    if (listType[selectState].form === 0) {
      formWorkLoad.setFieldsValue({
        institutions: "",
        qualification: "",
        year: "",
      });
    } else if (listType[selectState].form === 1) {
      setFileList([]);
      formWorkLoad.setFieldsValue({
        year: "",
        type: "",
        refer: "",
        link: "",
        file: "",
      });
    } else if (listType[selectState].form === 2) {
      setRadioOpt("เพิ่มจากระบบ");
      formWorkLoad.setFieldsValue({
        expertise: "",
        attention: "",
        from: "เพิ่มจากระบบ",
      });
    } else {
      formWorkLoad.setFieldsValue({
        year: "",
        name: "",
        budget_by: "",
        budget: null,
        proportion: 1,
      });
    }
  };

  useEffect(() => {
    async function init() {
      setSkipPage({ limit: 50, skip: 0 });
      setTotalPage(50);

      await getQuery(selectState, { limit: 50, skip: 0 });

      const listForm = [
        [
          {
            title: "No.",
            dataIndex: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
          },
          {
            title: "วุฒิการศึกษา",
            dataIndex: "qualification",
            align: "left",
            render: (text) => <span className="break-all">{text}</span>,
            sorter: (a, b) => (a.qualification < b.qualification ? -1 : 1),
          },
          {
            title: "สถาบันการศึกษา",
            dataIndex: "institutions",
            align: "left",
            render: (text) => <span className="break-all">{text}</span>,
            sorter: (a, b) => (a.institutions < b.institutions ? -1 : 1),
          },
          {
            title: "ปีการศึกษาที่จบ",
            dataIndex: "year",
            align: "center",
            sorter: (a, b) => (a.year < b.year ? -1 : 1),
          },
          {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text) => (
              <div className="flex gap-7 p-2 justify-center">
                <EditOutlined
                  className="text-orange-500 cursor-pointer text-2xl"
                  onClick={() => {
                    formWorkLoad.setFieldsValue({
                      institutions: text.institutions,
                      qualification: text.qualification,
                      year: dayjs(text.year + `-01-01`),
                    });
                    setModalContent({
                      open: true,
                      title: listType[selectState].label,
                      id: text.id,
                    });
                  }}
                />

                <DeleteOutlined
                  className="text-red-500 cursor-pointer text-2xl"
                  onClick={() =>
                    Swal.fire({
                      title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                      text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                      icon: "warning",
                      confirmButtonColor: "#de1a16",
                      showCancelButton: true,
                      confirmButtonText: "ยืนยัน",
                      cancelButtonText: "ยกเลิก",
                      preConfirm: () => handleDelete(text),
                    })
                  }
                />
              </div>
            ),
          },
        ],
        [
          {
            title: "No.",
            dataIndex: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
            render: (text) => <span className="break-all">{text}</span>,
          },
          {
            title: listType[selectState].label,
            dataIndex: "refer",
            align: "left",
            sorter: (a, b) => (a.refer < b.refer ? -1 : 1),
          },
          selectState === "personal_work"
            ? {
                title: "ประเภทผลงาน",
                dataIndex: "type",
                align: "center",
                sorter: (a, b) => (a.type < b.type ? -1 : 1),
              }
            : null,
          {
            title: ["director", "reward", "train", "personal_work"].includes(
              selectState
            )
              ? "ปี"
              : "ปีที่ตีพิมพ์",
            dataIndex: "year",
            align: "center",
            sorter: (a, b) => (a.year < b.year ? -1 : 1),
          },
          selectState === "reward"
            ? {
                title: "หน่วยงานที่มอบรางวัล",
                dataIndex: "institution",
                align: "left",
                sorter: (a, b) => (a.institution < b.institution ? -1 : 1),
              }
            : selectState === "train"
            ? {
                title: "หน่วยงานที่จัดอบรม",
                dataIndex: "institution",
                align: "left",
                sorter: (a, b) => (a.institution < b.institution ? -1 : 1),
              }
            : null,
          selectState === "certificate"
            ? null
            : {
                title: "ลิงก์ที่เกี่ยวข้อง",
                dataIndex: "link",
                align: "center",
                sorter: (a, b) => (a.link < b.link ? -1 : 1),
              },
          {
            title: "ไฟล์แนบ",
            dataIndex: "file",
            align: "center",
            render: (text) => {
              let files = text ? JSON.parse(text) : "";

              if (files?.length > 0) {
                return (
                  <div className="flex justify-center items-center flex-col gap-2">
                    {files.map((x) =>
                      !x.file ? null : x.file.includes("application/pdf") ? (
                        <FilePdfOutlined
                          className="bg-gray-200 p-2 justify-center text-5xl w-20 h-20 text-red-500"
                          onClick={() => onPreview({ thumbUrl: x.file })}
                        />
                      ) : (
                        <Image
                          width={80}
                          height={80}
                          className="bg-gray-200 p-2"
                          src={x.file}
                        />
                      )
                    )}
                  </div>
                );
              } else return null;
            },
          },
          {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text) => (
              <div className="flex gap-7 p-2 justify-center">
                <EditOutlined
                  className="text-orange-500 cursor-pointer text-2xl"
                  onClick={() => {
                    formWorkLoad.setFieldsValue({
                      year: dayjs(text.year + `-01-01`),
                      type: text.type,
                      refer: text.refer,
                      link: text.link,
                    });

                    if (text.file) {
                      setFileList(
                        JSON.parse(text.file).map((x, i) => ({
                          thumbUrl: x.file,
                          name: x.name || x.filename,
                          uid: "pic" + i,
                        }))
                      );
                    }

                    setModalContent({
                      open: true,
                      title: listType[selectState].label,
                      id: text.id,
                    });
                  }}
                />

                <DeleteOutlined
                  className="text-red-500 cursor-pointer text-2xl"
                  onClick={() =>
                    Swal.fire({
                      title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                      text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                      icon: "warning",
                      confirmButtonColor: "#de1a16",
                      showCancelButton: true,
                      confirmButtonText: "ยืนยัน",
                      cancelButtonText: "ยกเลิก",
                      preConfirm: () => handleDelete(text),
                    })
                  }
                />
              </div>
            ),
          },
        ],
        [
          {
            title: "No.",
            dataIndex: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
          },
          {
            title: listType[selectState].label,
            dataIndex: selectState,
            align: "left",
            render: (text) => <span className="break-all">{text}</span>,
            sorter: (a, b) => (a[selectState] < b[selectState] ? -1 : 1),
          },
          {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text) => (
              <div className="flex gap-7 p-2 justify-center">
                <EditOutlined
                  className="text-orange-500 cursor-pointer text-2xl"
                  onClick={() => {
                    formWorkLoad.setFieldsValue({
                      expertise: text.expertise,
                      from: "เพิ่มจากระบบ",
                    });

                    setModalContent({
                      open: true,
                      title: listType[selectState].label,
                      id: text.id,
                    });
                  }}
                />

                <DeleteOutlined
                  className="text-red-500 cursor-pointer text-2xl"
                  onClick={() =>
                    Swal.fire({
                      title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                      text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                      icon: "warning",
                      confirmButtonColor: "#de1a16",
                      showCancelButton: true,
                      confirmButtonText: "ยืนยัน",
                      cancelButtonText: "ยกเลิก",
                      preConfirm: () => handleDelete(text),
                    })
                  }
                />
              </div>
            ),
          },
        ],
        [
          {
            title: "No.",
            dataIndex: "no",
            align: "right",
            sorter: (a, b) => (a.no < b.no ? -1 : 1),
            render: (text) => <span className="break-all">{text}</span>,
          },
          {
            title: listType[selectState].label,
            dataIndex: "name",
            align: "left",
            sorter: (a, b) => (a.name < b.name ? -1 : 1),
          },
          {
            title: "เริ่มต้น (ปี)",
            dataIndex: "year_start",
            align: "center",
            sorter: (a, b) => (a.year_start < b.year_start ? -1 : 1),
          },
          {
            title: "สิ้นสุด (ปี)",
            dataIndex: "year_end",
            align: "center",
            sorter: (a, b) => (a.year_end < b.year_end ? -1 : 1),
          },
          {
            title: "งบสนับสนุนจาก",
            dataIndex: "budget_by",
            align: "left",
            sorter: (a, b) => (a.budget_by < b.budget_by ? -1 : 1),
          },
          {
            title: "งบสนับสนุน (บาท)",
            dataIndex: "budget",
            align: "right",
            render: (text) => formatMoney(text),
            sorter: (a, b) => (a.budget < b.budget ? -1 : 1),
          },
          {
            title: "สัดส่วนการทำงาน (%)",
            dataIndex: "proportion",
            align: "right",
            render: (text) => text + "%",
            sorter: (a, b) => (a.proportion < b.proportion ? -1 : 1),
          },
          {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text) => (
              <div className="flex gap-7 p-2 justify-center">
                <EditOutlined
                  className="text-orange-500 cursor-pointer text-2xl"
                  onClick={() => {
                    formWorkLoad.setFieldsValue({
                      year: [
                        dayjs(text.year_start + `-01-01`),
                        dayjs(text.year_end + `-01-01`),
                      ],
                      name: text.name,
                      budget_by: text.budget_by,
                      budget: text.budget,
                      proportion: text.proportion,
                    });

                    setModalContent({
                      open: true,
                      title: listType[selectState].label,
                      id: text.id,
                    });
                  }}
                />

                <DeleteOutlined
                  className="text-red-500 cursor-pointer text-2xl"
                  onClick={() =>
                    Swal.fire({
                      title: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
                      text: "กรุณาตรวจสอบข้อมูลก่อนลบ",
                      icon: "warning",
                      confirmButtonColor: "#de1a16",
                      showCancelButton: true,
                      confirmButtonText: "ยืนยัน",
                      cancelButtonText: "ยกเลิก",
                      preConfirm: () => handleDelete(text),
                    })
                  }
                />
              </div>
            ),
          },
        ],
      ];

      setColumns(listForm[listType[selectState].form]);
    }

    setDefaultForm();
    init();
  }, [selectState]);

  return (
    <>
      <LoadingComponent open={loading} />
      <SelectTableComponent
        selectOption={Object.keys(listType).map((x) => ({
          value: x,
          label: listType[x].label,
        }))}
        selectState={selectState}
        setSelectState={setSelectState}
        dataSource={dataSource}
        columns={columns}
        totalPage={totalPage}
        skipPage={skipPage}
        setSkipPage={setSkipPage}
        onChangeSkip={onChangeSkip}
        onChangeModal={() => {
          setDefaultForm();
          setModalContent({
            open: true,
            title: listType[selectState].label,
          });
        }}
      />
      <Modal
        title={modalContent.title}
        centered
        open={modalContent.open}
        // onOk={() => setOpen(false)}
        onCancel={() => setModalContent({ open: false })}
        width={1000}
        footer={null}
      >
        <Form layout={"vertical"} form={formWorkLoad} onFinish={handleSave}>
          <Card
            bordered={true}
            type="inner"
            style={{
              boxShadow:
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}
          >
            {listType[selectState].form === 0 ? (
              <>
                <Form.Item
                  label="วุฒิการศึกษา"
                  name="qualification"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก ${label}!",
                    },
                  ]}
                >
                  <Input placeholder="เช่น วศ.บ. (วิศวกรรมโยธา)" />
                </Form.Item>
                <Form.Item
                  label="สถาบันการศึกษา"
                  name="institutions"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก ${label}!",
                    },
                  ]}
                >
                  <Input placeholder="มหาวิทยาลัย" />
                </Form.Item>
                <Form.Item
                  name="year"
                  label="ปีการศึกษาที่จบ"
                  rules={[{ required: true, message: "กรุณาเลือก ${label}!" }]}
                >
                  <DatePicker
                    picker="year"
                    className="w-full"
                    placeholder="เลือกปี"
                  />
                </Form.Item>
              </>
            ) : listType[selectState].form === 1 ? (
              <>
                <Form.Item
                  name="year"
                  label={
                    ["director", "reward", "train", "personal_work"].includes(
                      selectState
                    )
                      ? "ปี"
                      : "ปีที่ตีพิมพ์"
                  }
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <DatePicker
                    picker="year"
                    className="w-full"
                    placeholder="เลือกปี"
                  />
                </Form.Item>

                {selectState === "personal_work" ? (
                  <Form.Item
                    label={"ประเภทผลงาน"}
                    name={"type"}
                    rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                  >
                    <Select
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...personalWork.map((x) => ({ value: x, label: x })),
                      ]}
                    />
                  </Form.Item>
                ) : null}

                <Form.Item
                  label={
                    ["reward", "train", "personal_work"].includes(selectState)
                      ? `ชื่อ${listType[selectState].label}`
                      : `อ้างอิง (${listType[selectState].label})`
                  }
                  name={"refer"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Input.TextArea
                    placeholder={listType[selectState].label}
                    autoSize={{ minRows: 4 }}
                  />
                </Form.Item>

                {selectState === "reward" ? (
                  <Form.Item
                    label="หน่วยงานที่มอบรางวัล"
                    name={"institution"}
                    rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                  >
                    <Input.TextArea
                      placeholder="หน่วยงานที่มอบรางวัล"
                      autoSize={{ minRows: 1 }}
                    />
                  </Form.Item>
                ) : selectState === "train" ? (
                  <Form.Item
                    label="หน่วยงานที่จัดอบรม"
                    name={"institution"}
                    rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                  >
                    <Input.TextArea
                      placeholder="หน่วยงานที่จัดอบรม"
                      autoSize={{ minRows: 1 }}
                    />
                  </Form.Item>
                ) : null}

                {selectState === "certificate" ? null : (
                  <Form.Item label="เพิ่มลิงก์" name="link">
                    <Input placeholder="ลิงก์" />
                  </Form.Item>
                )}

                <Form.Item label="เพิ่มไฟล์" name="file">
                  <Upload
                    listType="picture"
                    multiple={true}
                    fileList={fileList}
                    beforeUpload={() => false}
                    // onPreview={() => false}
                    onPreview={onPreview}
                    onChange={async (info) => {
                      if (info.file.status === "removed") {
                        setFileList((x) =>
                          [...x].filter((y) => y.uid !== info.file.uid)
                        );
                      } else {
                        if (
                          [
                            "image/png",
                            "image/jpeg",
                            "application/pdf",
                          ].includes(info.file.type)
                        ) {
                          const base64 = await toBase64(info.file);
                          info.file.thumbUrl = base64;

                          setFileList((x) => [...x, info.file]);
                        } else {
                          message.error("ไม่รองรับนามสกุลไฟล์นี้");
                        }
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
                  </Upload>
                </Form.Item>
              </>
            ) : listType[selectState].form === 2 ? (
              <>
                <Form.Item
                  name={"from"}
                  label={"ข้อมูลจาก"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Radio.Group
                    options={[
                      {
                        label: "เพิ่มจากระบบ",
                        value: "เพิ่มจากระบบ",
                      },
                      {
                        label: "เพิ่มด้วยตนเอง",
                        value: "เพิ่มด้วยตนเอง",
                      },
                    ]}
                    onChange={(e) => {
                      setRadioOpt(e.target.value);
                      formWorkLoad.setFieldValue(selectState, "");
                    }}
                    value={radioOpt}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
                <Form.Item
                  name={selectState}
                  label={`${
                    selectState === "expertise" ? "ความเชี่ยวชาญ" : "ความสนใจ"
                  }`}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  {radioOpt === "เพิ่มจากระบบ" ? (
                    <Select
                      options={[
                        {
                          value: "",
                          label: "กรุณาเลือก",
                          disabled: true,
                        },
                        ...dataS.map((x) => ({
                          value: x.name,
                          label: x.name,
                        })),
                      ]}
                    />
                  ) : (
                    <Input placeholder="กรุณากรอก" defaultValue="" />
                  )}
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="year"
                  label="เริ่มต้น-สิ้นสุด (ปี)"
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <DatePicker.RangePicker
                    className="w-full"
                    picker="year"
                    placeholder={["เริ่มต้น", "สิ้นสุด"]}
                  />
                </Form.Item>

                <Form.Item
                  label={`ชื่อโครงการ`}
                  name={"name"}
                  rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                >
                  <Input.TextArea
                    placeholder={listType[selectState].label}
                    autoSize={{ minRows: 4 }}
                  />
                </Form.Item>

                <div className="flex gap-4">
                  <Form.Item
                    className="w-1/2"
                    label={`งบสนับสนุนจาก`}
                    name={"budget_by"}
                    rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                  >
                    <Input.TextArea autoSize placeholder="งบสนับสนุนจาก" />
                  </Form.Item>
                  <Form.Item
                    className="w-1/2"
                    label={`งบสนับสนุน (สามารถเว้นว่างได้)`}
                    name={"budget"}
                    // rules={[{ required: true, message: "กรุณากรอก ${label}!" }]}
                  >
                    <Input
                      type="number"
                      placeholder="บาท"
                      defaultValue={null}
                      min={0}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  className="w-1/4"
                  label={`สัดส่วนการทำงาน (%)`}
                  name={"proportion"}
                  rules={[
                    { required: true, message: "กรุณากรอก ${label}!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (Number(value) > 100) {
                          return Promise.reject(
                            new Error(`กรุณากรอกค่า % ให้ถูกต้อง!`)
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input type="number" min={1} max={100} placeholder="1-100" />
                </Form.Item>
              </>
            )}

            <div className="flex justify-center items-center gap-2">
              <Button
                key="back"
                className="w-1/4"
                onClick={() => {
                  setModalContent({ open: false });
                }}
              >
                ยกเลิก
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                className="bg-blue-500 w-1/4"
                loading={loading}
              >
                บันทึก
              </Button>
            </div>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default WorkloadPage;
