import React, { useState, useEffect } from "react";
import {
  Avatar,
  Card,
  Image,
  Skeleton,
  Statistic,
  Row,
  Col,
  Tooltip,
  Space,
  Divider,
  List,
  Tabs,
} from "antd";
import {
  StarOutlined,
  LikeOutlined,
  MessageOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { API_ENDPOINT } from "../config/index";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { getData, onPreview } from "../utils";
import { useParams } from "react-router-dom";

const tabList = [
  {
    key: "expertise",
    label: "ความเชี่ยวชาญ",
  },
  {
    key: "print",
    label: "บทความวิจัยวิชาการที่ตีพิมพ์",
  },
  {
    key: "offer",
    label: "บทความวิจัยวิชาการที่นำเสนอในงานประชุมวิชาการ",
  },
  {
    key: "reward",
    label: "รางวัล",
  },
  {
    key: "personal_work",
    label: "ผลงาน",
  },
  {
    key: "research",
    label: "โครงการวิจัย",
  },

  // {
  //   key: "right",
  //   label: "สิทธิบัตร/อนุสิทธิบัตร",
  // },
  // {
  //   key: "artifact",
  //   label: "สิ่งประดิษฐ์",
  // },
  // {
  //   key: "copyright",
  //   label: "ลิขสิทธิ์",
  // },
  // {
  //   key: "book",
  //   label: "หนังสือแต่ง",
  // },
];

const IconText = ({ icon, text }) => (
  <Space>
    {icon}
    {text}
  </Space>
);

const ProgressProfilePage = () => {
  const params = useParams();
  const { id } = params;

  const [dataProfile, setDataPrifile] = useState({});

  const [dataExpertise, setDataExpertise] = useState([]);
  const [dataPrint, setDataPrint] = useState([]);
  const [dataOffer, setDataOffer] = useState([]);
  // const [dataRight, setDataRight] = useState([]);
  // const [dataArtifact, setDataArtifact] = useState([]);
  // const [dataCopyright, setDataCopyright] = useState([]);
  // const [dataBook, setDataBook] = useState([]);
  const [dataReward, setDataReward] = useState([]);
  const [dataPersonalWork, setDataPersonalWork] = useState([]);
  const [dataResearch, setDataResearch] = useState([]);

  const [loading, setLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState(0);

  const onTabChange = (key) => {
    const findIndex = tabList.findIndex((x) => x.key === key);

    setActiveTabKey(findIndex);
  };

  const getProfile = async () => {
    const res = await getData(`${API_ENDPOINT}/public/progressprofile/${id}`);

    if (res.data.ok && res.data.message.length > 0) {
      setDataPrifile(res.data.message[0]);
    }
  };

  const getProgress = async (_path, cb) => {
    const res = await getData(`${API_ENDPOINT}/public/${_path}/${id}`);

    if (res.data.ok && res.data.message.length > 0) {
      const files = res.data.message.map((x) => {
        if (x.file) {
          x.file = JSON.parse(x.file);
        }

        return x;
      });

      cb(files);
    }
  };

  useEffect(() => {
    async function init() {
      setLoading(true);

      await getProfile();

      const arrState = [
        setDataExpertise,
        setDataPrint,
        setDataOffer,
        setDataReward,
        setDataPersonalWork,
        setDataResearch,

        // setDataRight,
        // setDataArtifact,
        // setDataCopyright,
        // setDataBook,
      ];

      for (let i = 0; i < tabList.length; i++) {
        let x = tabList[i];

        await getProgress("progress" + x.key, arrState[i]);
      }

      setLoading(false);
    }

    init();
  }, []);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "none",
      height: 400,
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      min: 0,
      title: "",
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}; font-size: 14px;">{point.name}</span>: {point.y}<br/>',
    },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        data: [
          // dataExpertise,
          dataPrint,
          dataOffer,
          dataReward,
          dataPersonalWork,
          dataResearch,

          // dataRight,
          // dataArtifact,
          // dataCopyright,
          // dataBook,
        ].map((x, i) => ({
          name: tabList.filter((x) => x.key !== "expertise")[i].label,
          y: x?.length || 0,
          color: [
            "#FE2371",
            "#544FC5",
            "#2CAFFE",
            "#FE6A35",
            "#6B8ABC",
            // "#1C74BD",
            // "#00A6A6",
          ][i],
        })),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <div
        className="w-full h-full p-4 bg-gray-50"
        style={{
          backgroundImage:
            "linear-gradient(to top, #1677ff6b 0%, hsl(39deg 100% 68% / 53%) 100%)",
        }}
      >
        <>
          <div className="flex items-center gap-4 h-full">
            {loading ? (
              <Skeleton className="p-6" active />
            ) : (
              <>
                <div
                  className="w-1/3 h-5/6 p-4 flex flex-col gap-6 items-center justify-center"
                  bordered={false}
                  style={{
                    background: "#ffffff91",
                    backgroundImage:
                      "linear-gradient(to top, rgb(22 119 255 / 19%) 0%, rgb(255 198 92 / 28%) 100%)",
                    boxShadow:
                      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                  }}
                >
                  <div className="w-full flex flex-col items-center gap-4">
                    <div>
                      <Avatar
                        size={{
                          xs: 150,
                          sm: 150,
                          md: 200,
                          lg: 200,
                          xl: 200,
                          xxl: 190,
                        }}
                        src={dataProfile?.image_profile}
                        className="shadow-xl border-none"
                      />
                    </div>

                    <div
                      className="w-full flex gap-4 py-4 px-6 bg-gray-200 text-gray-700"
                      style={{
                        boxShadow:
                          "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                      }}
                    >
                      <label>ชื่อ - สกุล :</label>
                      <div className="flex flex-col gap-2">
                        <div>
                          <label>{dataProfile.fullname_th}</label>
                        </div>
                        <div>
                          <label>{dataProfile.fullname_en}</label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="w-full flex gap-4 px-0 pt-4 pb-0 bg-gray-200 text-gray-500"
                      style={{
                        boxShadow:
                          "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="w-2/3 h-5/6"
                  bordered={false}
                  style={{
                    background: "#ffffff91",
                    backgroundImage:
                      "linear-gradient(to top, rgb(22 119 255 / 19%) 0%, rgb(255 198 92 / 28%) 100%)",
                    boxShadow:
                      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                  }}
                >
                  <div className="w-full h-full">
                    <Tabs
                      defaultActiveKey={tabList[activeTabKey].key}
                      className="p-4 w-full h-full overflow-y-auto scrollbar-hide"
                      onChange={(key) => onTabChange(key)}
                      items={tabList.map((x) => {
                        return {
                          ...x,
                          children: (
                            <div className="w-full h-full px-2">
                              <List
                                itemLayout="horizontal"
                                className="w-full"
                                dataSource={
                                  [
                                    dataExpertise,
                                    dataPrint,
                                    dataOffer,
                                    dataReward,
                                    dataPersonalWork,
                                    dataResearch,
                                  ][activeTabKey]
                                }
                                renderItem={(item, index) => (
                                  <List.Item className="w-full" key={index}>
                                    <List.Item.Meta
                                      title={
                                        <div className="flex flex-col gap-1">
                                          <label>{item.refer}</label>
                                          {item.year || item.type ? (
                                            <span className="text-right">
                                              {item.type
                                                ? item.type + ", "
                                                : null}
                                              {item.year
                                                ? activeTabKey === 3 ||
                                                  activeTabKey === 4 ||
                                                  activeTabKey === 5
                                                  ? "ปี " + item.year
                                                  : "ปีที่ตีพิมพ์ " + item.year
                                                : null}
                                            </span>
                                          ) : null}
                                        </div>
                                      }
                                      description={
                                        <div className="pt-4 flex gap-1 flex-col">
                                          {(item?.file || []).map((f, i) => (
                                            <IconText
                                              icon={
                                                !f.file ? null : f.file.includes(
                                                    "application/pdf"
                                                  ) ? (
                                                  <FilePdfOutlined
                                                    className="text-xl text-red-500"
                                                    onClick={() =>
                                                      onPreview({
                                                        thumbUrl: f.file,
                                                      })
                                                    }
                                                  />
                                                ) : (
                                                  <Image
                                                    width={20}
                                                    height={20}
                                                    src={f.file}
                                                  />
                                                )
                                              }
                                              text={
                                                <a
                                                  onClick={() =>
                                                    onPreview({
                                                      thumbUrl: f.file,
                                                    })
                                                  }
                                                >
                                                  {f.name}
                                                </a>
                                              }
                                              key={"list-file" + i}
                                            />
                                          ))}
                                        </div>
                                      }
                                    />
                                  </List.Item>
                                )}
                              />
                            </div>
                          ),
                        };
                      })}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default ProgressProfilePage;
