import {
  admin_1,
  job_search,
  leasing,
  box,
  meeting,
  research,
  librarian,
  setting,
} from "../image";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const getUserData = () => {
  let obj = JSON.parse(localStorage.getItem("userstate"));

  if (obj?.roles) obj.roles = JSON.parse(obj.roles);

  return obj;
};

var USERDATA = getUserData();

var TOKEN = JSON.parse(localStorage.getItem("token"));
const AUTH = { headers: { Authorization: `JWT ${TOKEN}` } };
var GENDER = [
  {
    value: "ชาย",
    label: "ชาย",
  },
  {
    value: "หญิง",
    label: "หญิง",
  },
  {
    value: "LGBT",
    label: "LGBT",
  },
];
var PREFIX_NAME = {
  th: [
    {
      value: "นาย",
      label: "นาย",
    },
    {
      value: "นาง",
      label: "นาง",
    },
    {
      value: "นางสาว",
      label: "นางสาว",
    },
  ],
  en: [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
  ],
};
var BLOOD_TYPE = [
  {
    value: "ไม่ระบุ",
    label: "ไม่ระบุ",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "AB",
    label: "AB",
  },
  {
    value: "O",
    label: "O",
  },
];
var MARITAL_STATUS = [
  {
    value: "โสด",
    label: "โสด",
  },
  {
    value: "สมรส",
    label: "สมรส",
  },
  {
    value: "อย่าร้าง",
    label: "อย่าร้าง",
  },
  {
    value: "หม้าย",
    label: "หม้าย",
  },
];
var DISABILITY = [
  {
    value: "ไม่พิการ",
    label: "ไม่พิการ",
  },
  {
    value: "ความพิการทางการมองเห็น",
    label: "ความพิการทางการมองเห็น",
  },
  {
    value: "ความพิการทางการได้ยิน",
    label: "ความพิการทางการได้ยิน",
  },
  {
    value: "ความพิการทางสติปัญญา",
    label: "ความพิการทางสติปัญญา",
  },
  {
    value: "ความพิการร่างกาย,สุขภาพ",
    label: "ความพิการร่างกาย,สุขภาพ",
  },
  {
    value: "ความพิการทางเรียนรู้",
    label: "ความพิการทางเรียนรู้",
  },
  {
    value: "ความพิการทางพฤติกรรมและอารมณ์",
    label: "ความพิการทางพฤติกรรมและอารมณ์",
  },
  {
    value: "ความพิการทางออทิสติก",
    label: "ความพิการทางออทิสติก",
  },
  {
    value: "ความพิการซ้ำซ้อน",
    label: "ความพิการซ้ำซ้อน",
  },
];

var MENU_PATH = [
  {
    path: "booking/room",
    menu: "ข้อมูลผู้ใช้",
    label: "จองห้องประชุม",
    image: meeting,
    subpath: {
      form: {
        menu: "จองห้องประชุม",
        topic: "ขอความอนุเคราะห์ใช้ห้องประชุม",
        to: "หัวหน้าสำนักงานเลขานุการคณะฯ",
      },
      follow: {
        menu: "ติดตามสถานะ",
      },
    },
  },
  {
    path: "booking/car",
    menu: "ข้อมูลผู้ใช้",
    label: "จองรถ",
    image: leasing,
    subpath: {
      form: {
        menu: "จองรถ",
        topic: "ขออนุมัติใช้งานรถคณะฯ พร้อมด้วยพนักงานขับรถ",
        to: "คณบดีคณะโลจิสติกส์และดิจิทัลซัพพลายเชน",
      },
      follow: {
        menu: "ติดตามสถานะ",
      },
    },
  },
  {
    path: "booking/leave",
    menu: "ข้อมูลผู้ใช้",
    label: "ใบลา",
    image: box,
    subpath: {
      sick: {
        menu: "ลาป่วย/กิจ",
        topic: "แบบใบลาป่วย ลาคลอดบุตร ลากิจส่วนตัว",
      },
      relax: {
        menu: "ลาพักผ่อน",
        topic: "แบบใบลาพักผ่อน",
      },
      follow: {
        menu: "ติดตามสถานะ",
      },
    },
  },
  // {
  //   path: "booking/asset",
  //   menu: "ข้อมูลผู้ใช้",
  //   label: "ยืม (Software & Hardware)",
  //   image: librarian,
  //   subpath: {
  //     form: {
  //       menu: "ยืม",
  //       topic: "ขออนุมัติใช้งานการยืม",
  //       to: "คณบดีหรือรองคณบดีคณะโลจิสติกส์และดิจิทัลซัพพลายเชน",
  //     },
  //     follow: {
  //       menu: "ติดตามสถานะ",
  //     },
  //   },
  // },
  {
    path: "profile",
    label: "ข้อมูลส่วนตัว",
    menu: "ข้อมูลส่วนตัว",
    image: research,
    deactive: true,
  },
  {
    path: "workload",
    label: "อัพเดทผลงาน",
    menu: "อัพเดทผลงาน",
    image: job_search,
  },
  {
    path: "admin",
    label: "Admin Management",
    menu: "Admin Management",
    image: admin_1,
  },
  // {
  //   path: "setting",
  //   label: "ตั้งค่า",
  //   menu: "ตั้งค่า",
  //   image: setting,
  // },
];
var APPROVE_STATUS = [
  {
    color: "processing",
    label: "อยู่ระหว่างดำเนินการ",
  },
  {
    color: "success",
    label: "อนุมัติ",
  },
  {
    color: "red",
    label: "ไม่อนุมัติ",
  },
];
var LEAVE_CNT = {
  year: 10,
  person: 10,
};

export {
  AUTH,
  USERDATA,
  API_ENDPOINT,
  TOKEN,
  GENDER,
  PREFIX_NAME,
  BLOOD_TYPE,
  MARITAL_STATUS,
  DISABILITY,
  MENU_PATH,
  APPROVE_STATUS,
  LEAVE_CNT,
};
