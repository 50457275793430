import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pickImage from "./image";
import { genGroup } from "../utils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabun.ttf",
    bold: "THSarabun Bold.ttf",
    italics: "THSarabun Italic.ttf",
    bolditalics: "THSarabun BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

const FormRoom = (r, _x, _y, _z, room, facility, facilityID) => {
  let user_signature = _x?.image_signature || null;
  let user_name = _x?.fullname_th || _x?.fullname_en || null;
  let user_position = _x?.position_name || null;

  let one_signature = _y?.image_signature || null;
  let one_name = _y?.fullname_th || _y?.fullname_en || null;
  let one_position = _y?.position_name || null;

  let two_signature = _z?.image_signature || null;
  let two_name = _z?.fullname_th || _z?.fullname_en || null;
  let two_position = _z?.position_name || null;

  let create_Start = new Date(r.create_at);
  let TH_datecreate_date = create_Start.toLocaleDateString("th-TH", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  let date_Start = new Date(r.time_start);
  let TH_dateStart_day = date_Start.toLocaleDateString("th-TH", {
    day: "numeric",
  });
  let TH_dateStart_month = date_Start.toLocaleDateString("th-TH", {
    month: "long",
  });
  let TH_dateStart_year = date_Start.toLocaleDateString("th-TH", {
    year: "numeric",
  });
  let TH_dateStart_time = date_Start.toLocaleDateString("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });
  let Start_year = TH_dateStart_year.substring(TH_dateStart_year.length - 4);

  let Start_time = TH_dateStart_time.substring(TH_dateStart_time.length - 5);

  let date_End = new Date(r.time_end);
  let TH_dateEnd_day = date_End.toLocaleDateString("th-TH", { day: "numeric" });
  let TH_dateEnd_month = date_End.toLocaleDateString("th-TH", {
    month: "long",
  });
  let TH_dateEnd_year = date_End.toLocaleDateString("th-TH", {
    year: "numeric",
  });
  let TH_dateEnd_time = date_End.toLocaleDateString("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });
  let End_year = TH_dateEnd_year.substring(TH_dateEnd_year.length - 4);
  let End_time = TH_dateEnd_time.substring(TH_dateEnd_time.length - 5);
  var dd = {
    info: {
      title: "แบบใบขออนุมัติใช้ห้องประชุม",
    },
    content: [
      { image: "Krut", width: 60, alignment: "left" },
      {
        text: "บันทึกข้อความ",
        style: "header",
        alignment: "center",
        margin: [0, -40, 0, 0],
      },
      {
        margin: [0, 30, 0, 0],
        columns: [
          { text: "ส่วนราชการ", alignment: "left", style: "bigger" },
          {
            text: " คณะโลจิสติกส์และดิจิทัลซัพพลายเชน สำนักงานเลขานุการ งาน............................................โทร.......................................................",
            alignment: "left",
            margin: [-210, 2, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: "ที่",
            alignment: "left",
            margin: [0, 0, 0, 0],
            style: "bigger",
          },
          {
            text: "อว 0603.31.01(.........)/ ...........................",
            width: "auto",
            alignment: "left",
            margin: [-170, 2, 0, 0],
          },
          {
            text: "วันที่",
            alignment: "left",
            margin: [30, 0, 0, 0],
            style: "bigger",
          },
          {
            text: "..........................................................",
            alignment: "left",
            margin: [-130, 2, 0, 0],
          },
        ],
      },
      r.status === "อนุมัติ"
        ? {
            text: `${r.create_at ? TH_datecreate_date : ""}`,
            margin: [250, -19, 0, 0],
            alignment: "left",
          }
        : "",
      {
        margin: [0, 10, 0, 0],
        columns: [
          { text: "เรื่อง", alignment: "left", style: "bigger" },
          {
            text: ` ขออนุมัติใช้ห้องประชุม`,
            alignment: "left",
            margin: [-245, 2, 0, 0],
          },
        ],
      },
      {
        columns: [
          { text: "เรียน", alignment: "left", style: "bigger" },
          {
            text: ` หัวหน้างานการเงินและบัญชี`,
            alignment: "left",
            margin: [-243, 2, 0, 10],
          },
        ],
      },
      {
        margin: [55, 0, 0, 0],

        table: {
          widths: [25, 90, 30, 135, 20, 105],
          headerRows: 1,
          body: [
            [
              { text: "ข้าพเจ้า", border: [false, false, false, false] },
              {
                text: `${r.name}`,
                margin: [0, 0, 0, -3],
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "ตำแหน่ง", border: [false, false, false, false] },
              {
                text: user_position || "-",

                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "งาน", border: [false, false, false, false] },
              {
                text: _x.dept_name || "-",

                border: [false, false, false, true],
                alignment: "center",
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },

      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [77, 45, 112, 245],
          headerRows: 1,
          body: [
            [
              {
                text: "โทรศัพท์มือถือ/ที่ทำงาน",
                border: [false, false, false, false],
              },
              {
                text: `${r.phone ? r.phone : "-"}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              {
                text: "ขอความนุเคราะห์ใช้ห้องประชุมเพื่อ",
                border: [false, false, false, false],
              },
              r.description.includes("\n") || r.description.length > 56
                ? {
                    text: ``,
                    border: [false, false, false, false],
                    alignment: "center",
                  }
                : {
                    text: `${
                      r.description ? r.description.replace("\n") : "-"
                    }`,
                    border: [false, false, false, true],
                    alignment: "center",
                  },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      r.description.includes("\n") || r.description.length > 56
        ? {
            margin: [0, 0, 0, 0],

            table: {
              widths: [500],
              headerRows: 1,
              body: [
                [
                  {
                    text: `${
                      r.description ? r.description.replace("\n") : "-"
                    }`,
                    border: [false, false, false, true],
                    alignment: "left",
                  },
                ],
                [{ text: "", border: [false, false, false, false] }],
              ],
            },
            layout: {
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 10 } };
              },
            },
          }
        : {},

      {
        margin: [100, 0, 0, 0],

        table: {
          widths: [25, 10, 20, 40, 20, 30, 20, 30, 10, 30],
          headerRows: 1,
          body: [
            [
              { text: "ในวันที่", border: [false, false, false, false] },
              {
                text: `${TH_dateStart_day}`,
                margin: [0, 0, 0, -10],
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "เดือน", border: [false, false, false, false] },
              {
                text: `${TH_dateStart_month}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "พ.ศ.", border: [false, false, false, false] },
              {
                text: `${Start_year}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "เวลา", border: [false, false, false, false] },
              {
                text: `${Start_time}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "ถึง", border: [false, false, false, false] },
              {
                text: `${End_time}`,
                border: [false, false, false, true],
                alignment: "center",
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [100, 0, 0, 0],

        table: {
          widths: [25, 10, 20, 40, 20, 30, 20, 30, 10, 30],
          headerRows: 1,
          body: [
            [
              { text: "ถึงวันที่", border: [false, false, false, false] },
              {
                text: `${TH_dateEnd_day}`,
                margin: [0, 0, 0, -10],
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "เดือน", border: [false, false, false, false] },
              {
                text: `${TH_dateEnd_month}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "พ.ศ.", border: [false, false, false, false] },
              {
                text: `${End_year}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "เวลา", border: [false, false, false, false] },
              {
                text: `${Start_time}`,
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "ถึง", border: [false, false, false, false] },
              {
                text: `${End_time}`,
                border: [false, false, false, true],
                alignment: "center",
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [100, 0, 0, 0],

        table: {
          widths: [75, 20, 20],
          headerRows: 1,
          body: [
            [
              {
                text: "จำนวนผู้เข้าร่วมประชุม",
                border: [false, false, false, false],
              },
              {
                text: `${r.people}`,
                margin: [0, 0, 0, -3],
                border: [false, false, false, true],
                alignment: "center",
              },
              { text: "คน", border: [false, false, false, false] },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },

      ////// ROOM CHECKBOX //////
      {
        columns: genGroup(room, 4).map((x) => ({
          stack: x.map((y) => ({
            columns: [
              {
                image: r.room_id === y.id ? "checKed" : "unchecKed",
                width: 10,
                margin: [15, 0, 0, 0],
              },
              {
                text: `ห้องประชุม ${y.room_name}`,
              },
            ],
          })),
        })),
      },
      "\n",
      {
        text: "มีความประสงค์ขอใช้โสตทัศนูปกรณ์",
        margin: [90, 0, 0, 0],
        alignment: "left",
      },
      ////// FACILITY CHECKBOX //////
      "\n",
      {
        columns: genGroup(facility, 4).map((x) => ({
          stack: x.map((y) => ({
            columns: [
              {
                image: facilityID.includes(y.id) ? "checKed" : "unchecKed",
                width: 10,
                margin: [15, 0, 0, 0],
              },
              {
                text: `${y.facility_name}`,
              },
            ],
          })),
        })),
      },
      "\n",

      {
        text: "ทั้งนี้ ผู้ขอใช้ยินดีรับผิดชอบในกรณีที่เกิดความเสียหายต่ออุปกรณ์หรือค่าปฏิบัติงานนอกเวลาราชการ",
        margin: [90, 0, 0, 0],
        alignment: "left",
      },

      {
        margin: [170, 10, 0, 0],

        table: {
          widths: [20, 30, 100],
          headerRows: 1,
          body: [
            [
              {
                image: "one",
                margin: [0, 20, 0, 0],
                width: 15,
                border: [false, false, false, false],
              },
              {
                text: "(ผู้ขอใช้)",
                margin: [0, 20, 0, 0],
                border: [false, false, false, false],
              },
              user_signature
                ? {
                    image: `${user_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [170, 0, 0, 0],

        table: {
          widths: [30, 125],
          headerRows: 1,
          body: [
            [
              { text: "ตำแหน่ง", border: [false, false, false, false] },
              {
                text: `${!user_position ? "" : user_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, true],
                alignment: "center",
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 70, 0, 0],

        table: {
          widths: [140],
          headerRows: 1,
          body: [
            [
              {
                text: "เรียน หัวหน้างานการเงินและบัญชี",
                style: "bigger",
                border: [false, false, false, false],
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [15, 80],
          headerRows: 1,
          body: [
            [
              {
                image: "two",
                width: 15,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: "เพื่อโปรดพิจารณาอนุมัติ",
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 10, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [{ text: "", border: [false, false, false, false] }],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              two_signature
                ? {
                    image: `${two_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(${!two_name ? "" : two_name})`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [0, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `${!two_position ? "" : two_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [300, -110, 0, 0],

        table: {
          widths: [15, 25, 15, 20, 15, 30],
          headerRows: 1,
          body: [
            [
              {
                image: "three",
                width: 15,
                margin: [0, -20, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: "คำสั่ง",
                style: "bigger",
                margin: [0, -20, 0, 0],
                border: [false, false, false, false],
              },
              {
                image: r.status === "อนุมัติ" ? "checKedCicle" : "cicle",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: "อนุมัติ",
                border: [false, false, false, false],
              },
              {
                image: r.status === "ไม่อนุมัติ" ? "checKedCicle" : "cicle",
                width: 10,
                border: [false, false, false, false],
              },
              {
                text: "ไม่อนุมัติ",
                border: [false, false, false, false],
              },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [340, 0, 0, 0],

        table: {
          widths: [25, 100],
          headerRows: 1,
          body: [
            [
              {
                text: "(ลงชื่อ)",
                margin: [0, 25, 0, 0],
                border: [false, false, false, false],
              },
              one_signature
                ? {
                    image: `${one_signature}`,
                    width: 100,
                    height: 30,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                    alignment: "center",
                  }
                : { text: "", border: [false, false, false, false] },
            ],
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [370, 0, 0, 0],

        table: {
          widths: [100],
          headerRows: 1,
          body: [
            [
              {
                text: `(${!one_name ? "" : one_name})`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
      {
        margin: [340, 0, 0, 0],

        table: {
          widths: [150],
          headerRows: 1,
          body: [
            [
              {
                text: `ตำแหน่ง ${!one_position ? "" : one_position}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
            [{ text: "", border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 1, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 10 } };
          },
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      bigger: {
        fontSize: 15,
        bold: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      font: "THSarabunNew",
    },
    images: {
      checKedCicle: pickImage.checKedCicle,
      three: pickImage.three,
      two: pickImage.two,
      one: pickImage.one,
      checKed: pickImage.checKed,
      unchecKed: pickImage.unchecKed,
      cicle: pickImage.cicle,
      Krut: pickImage.Krut,
    },
  };

  pdfMake.createPdf(dd).open();
};

export { FormRoom };
